<template>
  <v-dialog v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="550"
    style="z-index: 6">
    <v-form ref="addNormForm"
      @submit.prevent="submit">
      <v-card class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title class="d-flex"
          style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Dodatne jedinica mjere</h2>
            <v-spacer></v-spacer>
            <v-icon large
              @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row class="flex-wrap justify-center">
              <v-col>
                <v-col v-if="newUnits.length > 0">
                  <v-flex v-for="(unit, idx) in newUnits"
                    :key="idx">
                    <v-row class="pt-5">
                      <v-col class="pa-0">
                        <v-text-field outlined
                          class="pa-0"
                          dense
                          hide-details
                          v-model="unit.customItemUnit"
                          v-if="newUnits.length > 0"
                          label="Jedinica mjere">
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-0 pl-2">
                        <quantity-input outlined
                          class="pa-0"
                          dense
                          hide-details
                          v-if="newUnits.length > 0"
                          label="Količina"
                          v-model="unit.customItemAmount"
                          :rules="[rules.req]"></quantity-input>
                      </v-col>
                      <v-col class="pa-0 pl-2">
                        <v-text-field outlined
                          dense
                          hide-details
                          v-if="newUnits.length > 0"
                          :value="whStorageUnit"
                          disabled>
                        </v-text-field>
                      </v-col>
                      <v-col class="pa-0 pl-2">
                        <v-checkbox outlined
                          dense
                          class="mt-0"
                          width="20%"
                          v-if="newUnits.length > 0"
                          v-model="unit.sellingItem"
                          label="Prodajna jedinica">
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row class="pa-0 pt-0">
                      <v-col class="d-flex justify-center pa-0 mx-0 mt-0">
                        <v-checkbox class="pa-2 pt-0 mt-0"
                          outlined
                          v-if="newUnits.length > 0"
                          v-model="unit.defaultSale"
                          label="Zadana prodajna jedinica"
                          @change="
                            changeDefaultSaleCustom(unit.defaultSale, idx)
                            ">
                        </v-checkbox>
                        <v-checkbox class="pa-2 pt-0 mt-0"
                          outlined
                          v-if="newUnits.length > 0"
                          v-model="unit.defaultStore"
                          @change="
                            changeDefaultStoreCustom(unit.defaultStore, idx)
                            "
                          label="Zadana skladišna jedinica">
                        </v-checkbox>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row class="pa-5 justify-center"
                      v-if="idx < newUnits.length - 1">
                      <v-btn v-if="newUnits.length > 0"
                        class="mt-2"
                        style="z-index: 8"
                        bottom
                        right
                        plain
                        @keyup.enter="removeUnit(unit.customItemUnit)"
                        @click.stop="removeUnit(unit.customItemUnit)">
                        <v-icon>mdi-minus</v-icon>
                        <span>Ukloni jedinicu</span></v-btn>
                    </v-row>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-row class="pa-5 justify-center">
                    <v-btn class="mt-2"
                      style="z-index: 8"
                      bottom
                      right
                      plain
                      @keyup.enter="addMoreUnits"
                      @click.stop="addMoreUnits">
                      <v-icon>mdi-plus</v-icon>
                      <span>Dodaj jedinicu</span></v-btn>
                    <v-btn v-if="newUnits.length > 0"
                      class="mt-2"
                      style="z-index: 8"
                      bottom
                      right
                      plain
                      @keyup.enter="removeUnit()"
                      @click.stop="removeUnit()">
                      <v-icon>mdi-minus</v-icon>
                      <span>Ukloni jedinicu</span></v-btn>
                  </v-row>
                </v-col>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit">
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
// import { df } from '@/plugins/firebase'
// import state from '@/state'
import rules from '@/plugins/rules'
// import { clone } from '@/plugins/utils'
import QuantityInput from '../../../components/QuantityInput.vue'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'AddCategory',
  components: { QuantityInput },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: () => ({
    visible: false,
    name: undefined,
    submitting: false,
    picture: '',
    newUnits: [],
    pictureDirty: false,
    whStorageUnit: undefined,
    rules: {
      req: rules.req('Required field')
    },
    items: [],
    normItemSelected: undefined,
    norms: []
  }),
  computed: {

  },
  methods: {
    changeDefaultSaleCustom (value, index) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            if (key !== index) {
              this.newUnits[key].defaultSale = false
            }
          })
        }
        this.whDefaultSale = false
      }
    },
    changeDefaultStoreCustom (value, index) {
      if (value) {
        if (this.newUnits.length > 0) {
          this.newUnits.forEach((_, key) => {
            if (key !== index) {
              this.newUnits[key].defaultStore = false
            }
          })
        }
        this.whDefaultStore = false
      }
    },
    addMoreUnits () {
      const i = this.newUnits.length + 1
      this.newUnits.push({ id: uuidv4(), name: 'Mjerna jedinica', value: i, defaultSale: false, defaultStore: false, sellingItem: false })
    },
    removeUnit (unitName = undefined) {
      if (unitName !== undefined) {
        this.newUnits = this.newUnits.filter((unit, key) => {
          if (unitName !== unit.customItemUnit) {
            return unit
          }
        })
      } else {
        this.newUnits.pop()
      }
    },
    async open (defaultUnit, existing = undefined, firstOpen = false) {
      this.whStorageUnit = defaultUnit

      if (existing !== undefined) {
        this.newUnits = existing
      }

      if (firstOpen) {
        this.addMoreUnits()
      }
      this.visible = true
    },
    close () {
      this.visible = false
      this.newUnits = []
      this.whStorageUnit = undefined
    },
    submit () {
      const that = this
      if (!this.$refs.addNormForm.validate()) return

      that.$emit('add', { units: this.newUnits, whDefaultStore: this.whDefaultStore, whDefaultSale: this.whDefaultSale })
      this.close()
    }
  }
}

</script>
