<template>
  <v-dialog v-model="visible"
    content-class="my-custom-dialog"
    @keydown.esc="close"
    persistent
    max-width="750"
    style="z-index: 6; height: auto !important">
    <v-overlay v-if="loading"
      absolute
      z-index="10">
      <v-progress-circular indeterminate
        size="64"
        color="primary"></v-progress-circular>
    </v-overlay>
    <v-form ref="addAssociateForm"
      @submit.prevent="submit">
      <v-card tile
        color="white"
        class="pb-3">

        <!-- Title -->
        <v-card-title class="d-flex"
          style="background-color: #1577da; height: 58px; color: white;">
          <span class="text-h5"
            style="font-weight: bold">{{ $t("$vuetify.company.addAssociate") }}</span>
        </v-card-title>

        <!-- Tabs -->
        <v-tabs v-model="activeTab"
          background-color="transparent"
          grow>
          <v-tab v-for="(tab, index) in tabs"
            :key="index">{{ tab }}</v-tab>
        </v-tabs>

        <v-card-text>
          <v-tabs-items v-model="activeTab">

            <!-- Tab 1: Osnovni podaci -->
            <v-tab-item>
              <v-row class="pt-2">
                <v-col cols="6">
                  <v-text-field autofocus
                    class="textField"
                    dense
                    outlined
                    hide-details
                    :label="$t('$vuetify.formDetails.name')"
                    v-model="name"
                    :rules="[rules.req]"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field :label="$t('$vuetify.formDetails.associateCode')"
                    v-model="code"
                    class="textField"
                    dense
                    hide-details
                    outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-combobox class="textField"
                    dense
                    outlined
                    hide-details
                    v-model="oibs"
                    :rules="[rules.arrReq]"
                    :items="[]"
                    :label="$t('$vuetify.formDetails.oib')"
                    multiple
                    chips
                    clearable>
                    <template v-slot:selection="{ item }">
                      <v-chip outlined
                        small
                        close
                        @click:close="removeOib(item)"
                        color="success">
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-select :label="$t('$vuetify.formDetails.associateType')"
                    multiple
                    class="textField"
                    hide-details
                    :items="associateTypes"
                    v-model="selectedAssociateTypes"
                    item-text="name"
                    item-value="value"
                    :rules="[rules.arrReq]"
                    small-chips
                    deletable-chips
                    dense
                    outlined></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="IBAN"
                    v-model="ibans"
                    class="textField"
                    dense
                    hide-details
                    outlined></v-text-field>
                </v-col>

              </v-row>
              <v-row>
                <v-col>
                  <v-textarea :label="$t('$vuetify.formDetails.additionalInfo')"
                    v-model="additional_info"
                    dense
                    class="textField"
                    outlined></v-textarea>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Tab 2: Adresa -->
            <v-tab-item>
              <v-row class="pt-1">
                <v-col>
                  <v-select :label="$t('$vuetify.formDetails.country')"
                    :items="countries"
                    v-model="country"
                    class="textField"
                    item-text="country"
                    item-value="country"
                    :rules="[rules.req]"
                    dense
                    hide-details
                    outlined></v-select>
                </v-col>
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.address')"
                    v-model="address"
                    class="textField"
                    :rules="[rules.req]"
                    dense
                    hide-details
                    outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.city')"
                    v-model="city"
                    :rules="[rules.req]"
                    class="textField"
                    dense
                    hide-details
                    outlined></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.zipCode')"
                    v-model="zipCode"
                    :rules="[rules.req]"
                    dense
                    class="textField"
                    hide-details
                    outlined></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Tab 3: Kontakt -->
            <v-tab-item>
              <v-row class="pt-1">
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.phone')"
                    v-model="phone"
                    dense
                    outlined
                    class="textField"
                    hide-details></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.mobile')"
                    v-model="mobile"
                    dense
                    outlined
                    class="textField"
                    hide-details></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Email"
                    v-model="email"
                    :rules="[rules.email]"
                    dense
                    hide-details
                    class="textField"
                    outlined></v-text-field>
                </v-col>

                <v-col>
                  <v-text-field label="Web"
                    v-model="web"
                    dense
                    hide-details
                    class="textField"
                    outlined></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Tab 4: Mjesto troška -->
            <v-tab-item>
              <v-row>
                <v-col class="text-left">
                  <v-btn small
                    @click="openCostCenterDialog()"
                    class="btn successButton mb-2"><v-icon>mdi-plus</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-list>

                <span v-if="paymentCenters && paymentCenters.length > 0"
                  class="text-h5"
                  style="font-weight: bold">Mjesto
                  troška</span>
                <v-list-item v-for="(center, index) in paymentCenters"
                  :key="index">
                  <v-list-item-content>

                    <v-row>
                      <v-col cols="3">
                        <div class="pl-1 caption grey--text">Naziv</div>
                        <div class="pl-1 text-capitalize">{{ center.name }}</div>
                      </v-col>

                      <v-col cols="3">
                        <div class="pl-1 caption grey--text">Opis</div>
                        <div class="pl-1 text-capitalize">{{ center.description }}</div>
                      </v-col>

                      <v-col cols="4">
                        <div class="pl-1 caption grey--text">Adresa</div>
                        <div class="pl-1 text-capitalize">{{ showAddress(center.address) }}</div>
                      </v-col>

                      <v-col cols="2"
                        class="pt-7">
                        <v-row>
                          <v-btn icon
                            small
                            @click="openEditCostCenterDialog(index, center)">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn icon
                            small
                            color="red"
                            @click="deletePaymentCenter(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>

                    </v-row>

                  </v-list-item-content>

                </v-list-item>
              </v-list>

            </v-tab-item>

          </v-tabs-items>
        </v-card-text>

        <!-- Actions -->
        <v-card-actions class="d-flex pt-0 mt-0 pr-4 justify-end">
          <v-btn class="btn dangerButton"
            @click="close">Odustani</v-btn>
          <v-btn id="success"
            class="btn successButton"
            :loading="submitting"
            type="submit">Spremi</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <cost-center-dialog @addCostCenter="addCostCenter"
      @editCostCenter="editCostCenter"
      ref="costCenterDialog"></cost-center-dialog>
  </v-dialog>
</template>
<script>
// import { df, auth } from '@/plugins/firebase'
import rules from '@/plugins/rules'
import state from '@/state'
import countries from '@/mixins/countries'
import { callAssociates } from '@/api/associates'
import CostCenterDialog from './CostCenterDialog.vue'
import { clone } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'

const optional = [
  'phone',
  'mobile',
  'email',
  'ibans',
  'web',
  'additional_info',
  'code'
]
export default {
  components: {
    CostCenterDialog
  },
  data: function (vm) {
    return {
      visible: false,
      submitting: false,
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        email: rules.email(),
        arrReq: rules.arrReq()
      },
      name: undefined,
      oib: undefined,
      existingOibs: undefined,
      address: undefined,
      city: undefined,
      zipCode: undefined,
      country: undefined,
      phone: undefined,
      mobile: undefined,
      email: undefined,
      ibans: undefined,
      web: undefined,
      additional_info: undefined,
      code: undefined,
      oibs: [],
      selectedAssociateTypes: [],
      associateTypes: [
        { name: vm.$t('$vuetify.formDetails.associateBuyer'), value: 'BUYER' },
        {
          name: vm.$t('$vuetify.formDetails.associateSupplier'),
          value: 'SUPPLIER'
        }
      ],
      activeTab: 0,
      tabs: [
        'Osnovni podaci',
        'Adresa',
        'Kontakt',
        'Mjesto troška'
      ],
      paymentCenters: [],
      loading: false
    }
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [countries],
  methods: {

    open (associates) {
      this.existingOibs = associates.flatMap((a) => {
        if (a.oibs) {
          return a.oibs.flat(x => x)
        }
      })

      this.visible = true
      this.submitting = false
    },
    close () {
      this.activeTab = 0
      this.visible = false
    },
    showAddress (address) {
      var adresa = ''
      if (address.street !== '') {
        adresa += address.street
      }

      if (address.city !== '') {
        adresa += ', ' + address.city
      }

      if (address.zipCode !== '') {
        adresa += ' ' + address.zipCode
      }

      if (address.country !== '') {
        adresa += ', ' + address.country
      }

      return adresa
    },

    openCostCenterDialog () {
      this.$refs.costCenterDialog.open(false)
    },
    openEditCostCenterDialog (index, costCenter) {
      this.$refs.costCenterDialog.open(true, clone(costCenter), index)
    },
    addCostCenter (costCenter) {
      var cc = clone(costCenter)
      cc.address.zip_code = cc.address.zipCode
      this.paymentCenters.push({ ...cc })
    },

    editCostCenter (index, costCenter) {
      this.paymentCenters[index] = costCenter
      this.$forceUpdate()
    },

    deletePaymentCenter (index) {
      this.paymentCenters.splice(index, 1)
    },
    removeOib (item) {
      this.oibs = this.oibs.filter((o) => o !== item)
    },
    validateOib () {
      let validated = true
      this.oibs.forEach(oib => {
        if (this.existingOibs.includes(oib)) {
          this.showMsgBox({
            text: this.$t('$vuetify.formDetails.oibDuplicate'),
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          validated = false
        }
      })
      return validated
    },

    async submit () {
      const that = this
      if (!this.$refs.addAssociateForm.validate()) return
      if (!this.validateOib()) return

      //   this.showLoader()
      this.submitting = true
      // const reqId = uuidv4()
      const payload = {
        action: {
          operation: 'set',
          entity: 'associate',
          params: {
            company_id: state.getCurrentCompany().id,
            name: this.name.toLowerCase(),
            oibs: this.oibs.map((oib) => oib.trim()),
            address: this.address.toLowerCase(),
            city: this.city.toLowerCase(),
            zip_code: this.zipCode,
            country: this.country,
            associate_type: this.selectedAssociateTypes.reduce(
              (associateTypes, associateType) =>
                Object.assign(associateTypes, { [associateType]: true }),
              {}
            )
          }
        }
      }

      if (this.paymentCenters && this.paymentCenters.length > 0) {
        payload.action.params.cost_centers = this.paymentCenters.reduce((acc, center) => {
          var id = uuidv4()
          center.id = id
          acc[id] = center
          return acc
        }, {})
      }

      optional.forEach((option) => {
        if (this[option] && this[option] !== '') {
          payload.action.params = {
            ...payload.action.params,
            [option]: option === 'ibans' ? [this[option]] : this[option]
          }
        }
      })

      var path = `/associates/${state.getCurrentCompany().id}/associate`
      var response = await callAssociates('post', path, payload.action.params)

      if (response.status === 200 && response.data) {
        that.$emit('success', response.data)
        setTimeout(() => {
          that.$refs.addAssociateForm.reset()
          that.submitting = false
          that.visible = false
        }, 100)
      } else {
        that.showMsgBox({
          text: 'Neuspjelo dodavanje novog klijenta',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }

      that.$emit('success', payload.action.params)
    }
  }
}
</script>
<style scoped>
.textField>>>input {
  text-transform: uppercase !important;
}

.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px !important;
}

.card-associate {
  border-radius: 25px !important;
  height: 500px !important;
  overflow-y: hidden;
}

.textField>>>.v-input__slot {
  border-radius: 12px !important;
}
</style>
