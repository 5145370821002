<template>
  <div>
    <v-dialog v-model="dialogOpen"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      max-width="600"
      max-height="600"
      persistent
      style="height: 50px">
      <v-card tile
        color="white"
        class="pb-3">
        <v-card-title class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          ">
          <span class="text-h5"
            style="font-weight: bold">Račun</span>
        </v-card-title>
        <v-card-text style="padding: 0; margin: 0; overflow-x: hidden">
          <v-container>
            <v-form ref="accountForm"
              @submit.prevent="submit">
              <v-row dense
                class="pb-3">
                <v-col cols="3">
                  <v-text-field dense
                    outlined
                    hide-details
                    v-model="receiptNumber"
                    label="Broj računa"
                    required></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-menu ref="menu1"
                    v-model="invoiceDateOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense
                        outlined
                        hide-details
                        v-model="dateFormatted"
                        label="Datum računa"
                        persistent-hint
                        append-icon="mdi-calendar"
                        v-bind="attrs"
                        @blur="date = parseDate(dateFormatted)"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date"
                      no-title
                      @input="menu1 = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="5">
                  <v-select dense
                    hide-details
                    outlined
                    v-model="receiptType"
                    :items="receiptTypes"
                    label="Vrsta računa"
                    item-text="name"
                    item-value="value"
                    return-object
                    required></v-select>
                </v-col>
              </v-row>
              <v-row dense
                class="pt-0 pb-0 mt-0 mb-0">
                <v-col class="pt-0 pb-0 mt-0 mb-0"
                  cols="6"> </v-col>
              </v-row>
              <v-expansion-panels v-model="associatesPanel"
                style="margin-bottom: 10px">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Dodaj podatke o kupcu
                    <template v-slot:actions>
                      <v-icon> mdi-account-multiple-outline </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense
                      style="margin-top: -1rem">
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-combobox label="Kupac"
                          outlined
                          dense
                          :items="associates"
                          v-model="associateByName"
                          item-text="name"
                          item-value="text"
                          :disabled="!cashRegisterForTerminal"
                          @change="onSelectAssociate"></v-combobox>
                      </v-col>
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-text-field v-model="associateAddress"
                          label="Adresa"
                          outlined
                          dense
                          persistent-hint
                          :disabled="!cashRegisterForTerminal"></v-text-field>
                      </v-col>
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-combobox label="OIB"
                          outlined
                          dense
                          :items="associates"
                          v-model="associateByOib"
                          item-text="oibs[0]"
                          item-value="text"
                          :disabled="!cashRegisterForTerminal"
                          @change="onSelectAssociateOib"
                          @keyup.enter="onSelectAssociateOib"></v-combobox>
                      </v-col>
                    </v-row>
                    <v-row dense
                      style="margin-top: -1.3rem">
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-text-field v-model="associateZip"
                          label="Poštanski broj"
                          outlined
                          dense
                          hide-details
                          persistent-hint
                          :disabled="!cashRegisterForTerminal"></v-text-field>
                      </v-col>
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-text-field v-model="associateCity"
                          label="Grad"
                          outlined
                          hide-details
                          dense
                          persistent-hint
                          :disabled="!cashRegisterForTerminal"></v-text-field>
                      </v-col>
                      <v-col cols="4"
                        sm="4"
                        md="4">
                        <v-select :items="countries"
                          :label="$t('$vuetify.formDetails.country')"
                          v-model="associateCountry"
                          outlined
                          dense
                          hide-details
                          item-text="country"
                          item-value="country"
                          class="uppercased-input"
                          :disabled="!cashRegisterForTerminal"></v-select>
                      </v-col>
                    </v-row>
                    <v-row v-if="
                      inEu === true &&
                      associateCountry !== 'Hrvatska' &&
                      associateCountry !== undefined
                    "
                      class="pt-0 mt-0">
                      <v-col cols="12"
                        class="d-flex pt-0 mt-0 py-0 justify-end">
                        <v-btn class="mt-10 mr-10"
                          href="https://ec.europa.eu/taxation_customs/vies/?locale=hr"
                          target="_blank"
                          height="25">
                          Provjera VIES baze
                        </v-btn>
                        <v-radio-group v-model="selectedVies"
                          :rules="[(v) => !!v || 'Molimo odaberite']"
                          required
                          class="small-radio">
                          <v-radio v-for="vies in vieses"
                            :key="vies.value"
                            :value="vies.value"
                            :label="vies.name"
                            :rules="[rules.req]"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row v-if="
                      inEu === false &&
                      associateCountry !== 'Hrvatska' &&
                      associateCountry !== undefined
                    "
                      class="pt-0 mt-0">
                      <v-col cols="12"
                        class="d-flex pt-0 mt-0 py-0 justify-end">
                        <v-radio-group v-model="selectedCustomerType"
                          :rules="[(v) => !!v || 'Molimo odaberite']"
                          required
                          class="small-radio">
                          <v-radio v-for="type in customerTypes"
                            :key="type.value"
                            :value="type.value"
                            :label="type.name"
                            :rules="[rules.req]">
                          </v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-row class="pt-5"
                  v-if="showPnb()"
                  justify="center"
                  style="margin-bottom: -2rem">
                  <v-col cols="2"
                    class="pr-0">
                    <v-text-field v-model="pnbModel"
                      prefix="HR"
                      label="Model"
                      persistent-placeholder
                      outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="4"
                    class="pr-0">
                    <v-text-field v-model="pnb"
                      label="Poziv na broj"
                      persistent-placeholder
                      outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select :items="pnbTypes"
                      v-model="pnbType"
                      item-text="text"
                      item-value="value"
                      persistent-placeholder
                      outlined
                      dense
                      @change="onPnbTypeChange">
                    </v-select>
                  </v-col>
                </v-row>
              </v-expansion-panels>
              <v-expansion-panels style="margin-bottom: 10px">
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate>
                    Dodaj napomenu
                    <template v-slot:actions>
                      <v-icon> mdi-receipt-text-outline </v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row dense
                      style="margin-top: -1rem">
                      <v-col cols="12">
                        <v-textarea v-model="note"
                          :items="languages"
                          label="Napomena"
                          rows="3"></v-textarea>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row v-if="receiptType === 'T'"
                dense
                style="margin-top: 30px">
                <v-col v-if="receiptType === 'T'"
                  cols="6">
                  <v-menu v-model="valueDateOpen"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field dense
                        outlined
                        v-model="computedDateFormatted"
                        label="Datum valute"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date"
                      no-title
                      @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <!-- <v-row class="pb-3 pt-0 pr-5" style="text-align: right">
                <v-spacer></v-spacer>
                <v-checkbox
                  class="pb-0 mb-0"
                  dense
                  hide-details
                  v-model="payed"
                  color="primary"
                  label="Račun plaćen"
                ></v-checkbox>
              </v-row> -->
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex pt-0 mt-0 justify-end">
          <v-btn class="btn dangerButton"
            @click="close"> Natrag </v-btn>
          <v-btn id="success"
            class="btn successButton"
            type="submit"
            @click="receiptType.value === 'K' ? checkCards() : submit()">
            Završi
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay v-if="loading"
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
        style="z-index: 100">
        <div v-if="loading"
          class="text-center"
          style="
            position: absolute;
            z-index: 101;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          ">
          <v-progress-circular indeterminate
            size="86"
            color="primary">
          </v-progress-circular>
        </div>
      </v-overlay>
    </v-dialog>
    <card-payment-selection-dialog ref="cardSelectionDialog"
      :cards="availableCards"
      @checkTerminal="checkTerminal"></card-payment-selection-dialog>
    <terminals ref="terminals"
      @terminalSelected="terminalSelected"></terminals>
  </div>
</template>
<script>
import { auth, df, functions } from '@/plugins/firebase'
import { clone, DAY } from '@/plugins/utils'
import applicationSettings from '@/mixins/applicationSettings'
import rules from '@/plugins/rules'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
// import WriteInProductModel from '@/model/WriteInProduct'
import avansReceipt from '@/mixins/avansReceipt'
import { remapItems } from '@/libs/receiptIssuing/items'
import { getPaymentMethod, dateTimeToUnixTimestamp } from '@/libs/receiptIssuing/commons'
// import { sendHttp } from '@/libs/receiptIssuing/payment'
// import { reprint } from '@/libs/bixolon/reprint'
// import { print58 } from '@/libs/bixolon/print58'
import EventBus from '@/plugins/event-bus'
import CardPaymentSelectionDialog from '@/modules/cash-register/components/dialogs/CardPaymentSelectionDialog'
import Terminals from '@/modules/cash-register/components/dialogs/Terminals.vue'
import { retry } from '@/libs/receiptIssuing/validation'
import countries from '@/mixins/countries'

export default {
  components: {
    Terminals,
    CardPaymentSelectionDialog
  },
  mixins: [applicationSettings, avansReceipt, countries],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'confirm',
    'confirmClose',
    'openPdfPrint'
  ],
  props: {
    associates: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dialogOpen: false,
      loading: false,
      location: {},
      lastVisible: {},
      listeners: [],
      multiplier: 0,
      absolute: true,
      opacity: 0.46,
      overlay: true,
      register: null,
      document: null,
      last_balance_timestamp: new Date(new Date().setHours(0, 0, 0, 0)),
      avansItemName: 'Primljeni predujam',
      price: 0,
      base: 0,
      priceInputEnabled: true,
      currency: 'EUR',
      currencies: ['EUR', 'USD', 'GBP'],
      currencyOptions: {
        locale: 'de-DE',
        currency: 'EUR',
        currencyDisplay: 'symbol',
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        autoSign: true,
        useGrouping: true,
        accountingSign: false

      },
      rules: {
        req: rules.req(),
        money: rules.money(),
        arrReq: rules.arrReq()
      },
      pdv: {},
      priceTaxes: [],
      taxes: [],
      receiptType: { name: 'Gotovinski račun', value: 'G' },
      receiptTypes: [
        { name: 'Gotovinski', value: 'G' },
        { name: 'Kartično', value: 'K' },
        { name: 'Transakcijski račun', value: 'T' },
        { name: 'Ostalo', value: 'O' }
      ],
      invoiceDateOpen: false,
      valueDateOpen: false,
      deliveryDateOpen: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      cashRegisterNumber: undefined,
      associate: undefined,
      associateByName: undefined,
      associateByOib: undefined,
      associateAddress: undefined,
      associateZip: undefined,
      associateCity: undefined,
      associateCountry: undefined,
      availableCards: [],
      selectedCard: undefined,
      selectedTerminal: undefined,
      cashRegisterForTerminal: undefined,
      note: '',
      languages: [
        { name: 'Hrvatski', value: 'hr' },
        { name: 'English', value: 'en' }
      ],
      selectedLanguage: { name: 'Hrvatski', value: 'hr' },
      // associates: [],
      euCountries: [],
      wholeSaleRcptTypes: undefined,
      inEu: false,
      selectedVies: undefined,
      selectedCustomerType: undefined,
      pnb: undefined,
      pnbModel: undefined,
      pnbType: undefined,
      pnbTypes: [{ text: 'Generiraj poziv po broju racuna', value: 0, disabled: false }, { text: 'Generiraj poziv po OIB-u', value: 1, disabled: true }],
      payed: true,
      associatesPanel: []
    }
  },
  watch: {
    receiptType (nv) {
      if (nv.value === 'T') {
        this.pnbType = 0
        this.calculateReferenceNumber()
      }
    },
    associate (nv) {
      if (nv && nv.oibs && nv.oibs[0] !== '') {
        this.pnbTypes[1].disabled = false
      } else {
        this.pnbTypes[1].disabled = true
      }
    },
    associateCountry (nv) {
      this.inEu = false
      this.euCountries.forEach(country => {
        if (country === nv) {
          this.inEu = true
        }
      })
    },
    dialogOpen (val) {
      if (val === false) {
        this.tables = []
      }
    }
  },
  computed: {
    vieses () {
      return [
        { name: 'Kupac unutar VIES baze', value: 'inside' },
        { name: 'Kupac izvan VIES baze', value: 'outside' }
      ]
    },
    customerTypes () {
      return [
        { name: 'Kupac - pravna osoba', value: 'legal' },
        { name: 'Kupac - fizička osoba', value: 'person' }
      ]
    },
    receiptNumber () {
      var register = state.getCashRegister()
      if (this.cashRegisterForTerminal) {
        register = this.cashRegisterForTerminal
      }

      var location = state.getPointOfSale()
      if (this.location) {
        location = this.location
      }

      return this.cashRegisterNumber +
        state.getCurrentCompany().receipt_numeration_separator +
        location.location_id +
        state.getCurrentCompany().receipt_numeration_separator +
        register.number
    },
    isTaxed () {
      return state.getCurrentCompany().is_taxed
    },
    isPriceNull () {
      return this.price === 0 || this.base === 0
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    }
  },
  methods: {
    getOib () {
      let associate

      if (this.associateByName) {
        associate = this.associateByName
      }
      if (this.associateByOib && typeof (this.associateByOib) !== 'string') {
        associate = this.associateByOib
      }

      if (typeof (associate) === 'string') {
        // slobodan unos
        return this.associateByOib
      } else {
        // Odabir
        if (associate) {
          return associate.oibs[0]
        }
      }
    },
    getReferenceNumber () {
      if (this.pnbModel && this.pnb) {
        return `HR${this.pnbModel} ${this.pnb}`
      }
      return ''
    },
    calculateReferenceNumber () {
      let model = '00'
      let receiptNum = clone(this.receiptNumber)
      receiptNum = receiptNum.replaceAll('/', '-')
      if (this.pnbType === 0) {
        let formattedReceiptNumber = receiptNum.replace(/[^0-9-]+/, '')
        formattedReceiptNumber = formattedReceiptNumber.replaceAll('--', '-')
        this.pnbModel = model
        this.pnb = formattedReceiptNumber
      } else if (this.pnbType === 1) {
        const oib = this.getOib()
        if (!oib) return
        model = '01'
        const randomKey = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000
        const forSecurity = oib.toString() + randomKey.toString()
        const securityNum = this.controlDigit(forSecurity)
        this.pnbModel = model
        this.pnb = oib + '-' + randomKey + '-' + securityNum
      }
    },
    controlDigit (s) {
      var i = 0; var v = 0; var p = 2; var c = ' '
      for (i = s.length; i >= 1; i--) {
        c = s.substr(i - 1, 1)
        if (c >= '0' && c <= '9' && v >= 0) { v = v + p * c; p = p + 1 } else { v = -1 }
      };
      if (v >= 0) { v = 11 - (v % 11); if (v > 9) { v = 0 }; }
      return (v)
    },
    onPnbTypeChange () {
      this.calculateReferenceNumber()
    },
    addHR () {
      this.pnbModel = 'HR'
    },
    showPnb () {
      if (this.cashRegisterForTerminal && (this.cashRegisterForTerminal.type === 'VP' || this.cashRegisterForTerminal.type === 'ALL')) {
        if (this.receiptType.value === 'T') {
          return true
        }
      }
      return false
    },
    populateAddressFields (collection) {
      this.associateAddress = collection?.address ?? undefined
      this.associateZip = collection?.zip_code ?? undefined
      this.associateCity = collection?.city ?? undefined
      this.associateCountry = collection?.country ?? undefined
    },
    openCardSelectionDialog (type) {
      this.$refs.cardSelectionDialog.open(type)
    },
    checkCards () {
      if (!this.availableCards || this.availableCards.length === 0) {
        // Nema aktivnih kartica
        if (this.cashRegisterForTerminal.terminals) {
          var terms = this.cashRegisterForTerminal.terminals
          var termsArray = []
          if (terms && Object.keys(terms).length > 0) {
            Object.keys(terms).forEach(key => {
              termsArray.push(terms[key])
            })

            // Ako postoji vise terminala, dopusti odabir bilo kojeg
            if (termsArray && termsArray.length > 1) {
              this.$refs.terminals.open(this.cashRegisterForTerminal, undefined, 'K', 'BO')
            } else if (termsArray && termsArray.length === 1) {
              // Ako postoji ima samo jedan terminal, salji na njega
              this.finishPayment()
            } else {
              // Ako nema ni jedan terminal, samo napravi racun
              this.finishPayment('K', 'BO', undefined, undefined)
            }
          }
        } else {
          // Ako nema ni jedan terminal, samo napravi racun
          this.finishPayment('K', 'BO', undefined, undefined)
        }
      } else {
        // Ima aktivnih kartica, pokaži dialog za odabir
        this.openCardSelectionDialog('BO')
      }
    },
    checkTerminal (method, receiptType, deliveryName = undefined, paymentCard = undefined) {
      var termsWithCards = []
      if (this.cashRegisterForTerminal.terminals) {
        var terms = this.cashRegisterForTerminal.terminals

        Object.keys(terms).forEach(key => {
          if (terms[key].cards && terms[key].cards.length === 0) {
            termsWithCards.push(terms[key])
          }

          if (terms[key].cards && terms[key].cards.length > 0 && terms[key].cards.some(card => card.ID === paymentCard.id)) {
            termsWithCards.push(terms[key])
          }
        })
      }

      if (termsWithCards) {
        switch (termsWithCards.length) {
          case 0:
            this.submit(paymentCard)
            break
          case 1:
            var terminal = termsWithCards[0]
            this.submit(paymentCard, terminal)
            break
          default:
            this.$refs.terminals.open(this.cashRegisterForTerminal, paymentCard, method, receiptType)
            break
        }
      }
    },
    terminalSelected (terminal, paymentCard, method, receiptType) {
      this.submit(paymentCard, terminal)
    },
    onSelectAssociate () {
      this.populateAddressFields(this.associateByName)
      if (this.associateByName?.oibs && this.associateByName.oibs.length > 0) {
        this.associateByOib = this.associateByName.oibs[0]
      } else {
        this.associateByOib = undefined
      }
      this.pnbTypes[1].disabled = false
      this.calculateReferenceNumber()
    },

    onSelectAssociateOib () {
      const hasOibs = this.associateByOib?.oibs && this.associateByOib.oibs.length > 0
      if (hasOibs && this.takenOibs.includes(this.associateByOib.oibs[0])) {
        this.populateAddressFields(this.associateByOib)
        this.associateByName = this.associateByOib?.name ?? undefined
      }
      this.pnbTypes[1].disabled = false
      this.calculateReferenceNumber()
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },

    parseDate (date) {
      if (!date) return null
      const [month, day, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    submit (paymentCard = undefined, terminal = undefined) {
      if (this.receiptType.value === 'T') {
        var invalid = true
        switch (typeof (this.associateByName)) {
          case 'string':
            if (this.associateByName !== '') {
              invalid = false
            }
            break
          case 'object':
            if (this.associateByName.name !== '') {
              invalid = false
            }
            break
        }

        if (invalid === true) {
          this.confirm({
            title: 'Kupac nije odabran',
            message: 'Želite li izdati račun s nepoznatim kupcem?',
            options: {
              toolbar: true,
              width: 410,
              confirmText: 'OK',
              cancelText: 'Odustani'
            }
          }).then(response => {
            if (response) {
              this.confirmClose()
              this.associateByName = { name: 'Kupac građanin', id: 'kupac-gradanin' }
              this.createReceipt(paymentCard, terminal)
            }
          })
        } else {
          this.createReceipt(paymentCard, terminal)
        }
      } else {
        this.createReceipt(paymentCard, terminal)
      }
    },

    async createReceipt (paymentCard = undefined, terminal = undefined) {
      this.loading = true
      const method = getPaymentMethod(this.receiptType.value)
      var type = ''
      var fiscalize = false
      switch (this.receiptType.value) {
        case 'G':
          type = 'BO'
          fiscalize = true
          break
        case 'K':
          type = 'BO'
          fiscalize = true
          break
        case 'T':
          type = 'R'
          fiscalize = false
          break
      }

      const data = {
        currency: this.currency,
        fullTotal: this.avansFinishData.fullTotal,
        items: this.items,
        registerType: this.cashRegisterType,
        total: this.avansFinishData.total,
        type: method
      }

      const items = await remapItems(data, method, this.cashRegister)
      this.total = Math.round(this.total)

      var userID = auth.currentUser.uid
      if (state.getCashRegisterUser().id !== undefined && state.getCashRegisterUser().id !== '') {
        userID = state.getCashRegisterUser().id
      }

      const reqId = uuidv4()
      var receiptApi = functions.httpsCallable('receiptapi')

      var receiptNumber = await this.getCashRegisterNumber()

      const params = {
        company_id: state.getCurrentCompany().id,
        location_id: this.location.id,
        register_id: this.cashRegisterForTerminal.id,
        issue_date_time: dateTimeToUnixTimestamp(new Date()),
        payment_method: method,
        seller_oib: '',
        currency: 'EUR',
        payment_reference: '',
        language: 'hr',
        type: { flag: type },
        items: items,
        note: '',
        number: parseInt(receiptNumber),
        fiscalize: fiscalize,
        reqid: reqId,
        full_total: this.avansFinishData.fullTotal,
        discount: this.avansFinishData.discount,
        total: this.avansFinishData.total
      }

      let wholeSaleRcptType = ''

      params.payment_reference = this.getReferenceNumber()

      // POTREBNO ZBOG ISPISA ČLANKA NA RAČUNU
      if ((this.receiptType.value === 'T' || this.receiptType.value === 'P') && this.associateCountry !== undefined && this.associateCountry !== '' && this.associateCountry !== 'Hrvatska') {
        if (state.getCurrentCompany().is_taxed === true) {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'TAX_EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'TAX_EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'TAX_OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'TAX_OTHER_LEGAL_ENTITY'
            }
          }
        } else {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              wholeSaleRcptType = 'EU_VIES_TRUE'
            } else {
              wholeSaleRcptType = 'EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              wholeSaleRcptType = 'OTHER_PERSON'
            } else {
              wholeSaleRcptType = 'OTHER_LEGAL_ENTITY'
            }
          }
        }
      }

      if (wholeSaleRcptType !== '') {
        params.wholesale_receipt_type = wholeSaleRcptType
      }

      let associate

      if (this.associateByName) {
        associate = this.associateByName
      }
      if (this.associateByOib && typeof (this.associateByOib) !== 'string') {
        associate = this.associateByOib
      }

      if (typeof (associate) === 'string') {
        // slobodan unos
        params.type.payer_name = associate
        params.type.payer_oib = this.associateByOib
        params.type.payer_address = this.associateAddress
        params.type.payer_zip_code = this.associateZip
        params.type.payer_city = this.associateCity
        params.type.payer_country = this.associateCountry
      } else {
        // Odabir
        if (associate) {
          params.type.payer_address = this.associateAddress
          params.type.payer_city = this.associateCity
          params.type.payer_country = this.associateCountry
          params.type.payer_name = associate.name
          params.type.payer_oib = associate.oibs && associate.oibs[0] ? associate.oibs[0] : ''
          params.type.payer_zip_code = this.associateZip
        }
      }

      var printer = state.getSelectedPrinter()

      if (['T58', 'T80', 'A4'].includes(printer.width)) {
        params.print_size = printer.width
      } else {
        params.print_size = 'BIXOLON80'
      }

      if (this.avansFinishData.avansReceipts && this.avansFinishData.avansReceipts.length > 0) {
        var stornoReceipt = {
          tag: 'AVANS_DEDUCTED',
          receipts: []
        }

        this.avansFinishData.newReceipt.pdvs.forEach(p => {
          if (p.base > 0) {
            // var found = false
            this.avansFinishData.avansReceipts.forEach(receipt => {
              if (p.rate === receipt.pdv[0].rate) {
                // found = true
                var pdv = p

                var separator = receipt.company_details.rcpt_separator
                var rcptNumber = receipt.designation.number + separator + receipt.designation.location_id + separator + receipt.designation.register_number
                var rcpt = {
                  id: receipt.id,
                  location_id: receipt.designation.location_id,
                  number: receipt.designation.number,
                  register_number: receipt.designation.register_number,
                  receipt_number: rcptNumber,
                  issue_date_time: receipt.issue_date_time
                }

                var storno = {
                  receipt_id: receipt.id,
                  storno_tax_base: Math.round(receipt.pdv[0].base),
                  storno_tax_rate: receipt.pdv[0].rate,
                  storno_tax_total: Math.round(receipt.pdv[0].total),
                  storno_total: Math.round(receipt.pdv[0].base) + Math.round(receipt.pdv[0].total),
                  total: Math.round(pdv.base) + Math.round(pdv.pdv),
                  receipt: rcpt,
                  general_discount: Math.round(this.avansFinishData.generalDiscount),
                  general_discount_pdv: Math.round(this.avansFinishData.totalDiscountPdv),
                  new_tax_base: Math.round(pdv.base),
                  new_tax_rate: pdv.rate,
                  new_tax_total: Math.round(pdv.pdv)
                }

                stornoReceipt.receipts.push(storno)
              }
            })

            // if (found === false) {
            //   var pdv = p
            //   var rcpt = {
            //     id: '',
            //     location_id: '',
            //     number: 0,
            //     register_number: 0,
            //     receipt_number: '',
            //     issue_date_time: 1704971152
            //   }

            //   var storno = {
            //     receipt_id: '',
            //     storno_tax_base: 0,
            //     storno_tax_rate: 0,
            //     storno_tax_total: 0,
            //     storno_total: 0,
            //     total: Math.round(pdv.base) + Math.round(pdv.pdv),
            //     receipt: rcpt,
            //     general_discount: Math.round(this.avansFinishData.generalDiscount),
            //     new_tax_base: Math.round(pdv.base),
            //     new_tax_rate: pdv.rate,
            //     new_tax_total: Math.round(pdv.pdv)
            //   }

            //   stornoReceipt.receipts.push(storno)
            // }
          }
        })

        if (stornoReceipt?.receipts.length > 0) {
          if (!params.tags) {
            params.tags = []
          }
          params.tags.push({
            tag: 'RECEIPT_TYPE',
            receipt_type: 'AVANS_DEDUCTED'
          })

          params.tags.push(
            stornoReceipt
          )
        }
      }

      if (paymentCard) {
        if (!params.tags) {
          params.tags = []
        }
        params.tags.push({
          tag: 'PAYMENT_CARD',
          payment_card: paymentCard.name
        })
      }

      params.seller_id = userID

      if (terminal) {
        if (!params.tags) {
          params.tags = []
        }
        params.tags.push({
          tag: 'POS_TYPE',
          pos_type: terminal.type_name
        }, {
          tag: 'DEVICE_ID',
          device_id: terminal.device_id
        }, {
          tag: 'POS_ID',
          pos_id: terminal.pos_id
        })

        // if (state.getCashRegister().terminal_type === 'before') {
        const rID = uuidv4()
        const pl = {
          action: {
            operation: 'set',
            entity: 'pos',
            params: {
              company_id: state.getCurrentCompany().id,
              cash_register_id: this.cashRegisterForTerminal.id,
              pos_type: terminal.type_name,
              device_id: terminal.device_id,
              pos_id: terminal.pos_id,
              amount: params.total,
              merchant_reference: state.getCurrentCompany().id + '.' + reqId
            }
          }
        }

        df.doc(`request/${rID}`)
          .set({
            user_id: `${userID}`,
            device_id: 'web',
            created: `${dateTimeToUnixTimestamp(new Date())}`,
            type: 'cardpos',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(pl)))
            )
          })
          .catch((err) => {
            this.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
          })
        // }
      }

      if (params.type.flag === 'R') {
        params.payment_due = parseInt(dateTimeToUnixTimestamp(new Date(new Date().getTime() + 8 * DAY)))
      }

      params.payment_due = params.issue_date_time

      if (params.payment_method === 'TRANSAKCIJSKI RAČUN') {
        params.paid_transaction_receipt = false

        if (params.total === 0) {
          params.paid_transaction_receipt = true
        }
      }

      params.delivery_date = dateTimeToUnixTimestamp(new Date(this.avansFinishData.deliveryDate))

      receiptApi({
        action: {
          operation: 'set',
          entity: 'receipt',
          params: params
        }
      })
        .then((result) => {
          if (result && result.data && result.data && result.data.code !== 200) {
            this.loading = false

            var confirm = this.confirm
            var confirmClose = this.confirmClose
            confirm({
              title: 'Pogreška prilikom izdavanja računa',
              message: 'Molimo provjerite da li se račun kojeg ste upravo izdali nalazi na listi računa.',
              options: {
                toolbar: true,
                width: 410,
                confirmText: 'OK',
                hideCancel: true
              }
            }).then(_ => {
              confirmClose()
              EventBus.$emit('open_receipts')
            })
            return
          }

          if (result && result.data && result.data.zki !== '') {
            var receipt = result.data.full_receipt
            var receipttemplate = functions.httpsCallable('receiptTemplate')

            var documentSubType = 'racun'
            if (state.getCurrentCompany().receipt_type && state.getCurrentCompany().receipt_type !== '') {
              documentSubType = state.getCurrentCompany().receipt_type
            }

            var that = this
            receipttemplate({
              template: 'racun02',
              companyId: receipt.company_details.id,
              documentId: receipt.id,
              documentSubType: documentSubType,
              documentType: 'racun',
              rawJson: ''
            })
              .then((result) => {
                if (result.data?.document !== '') {
                  that.openPdfPrint(result.data?.document)
                  this.loading = false

                  EventBus.$emit('clear-receipt-data')
                  EventBus.$emit('clear-selection')
                  this.close()
                  this.$emit('finishReceipt')
                  EventBus.$emit('stop-loader')
                }
              })

            // if (!printer.width || printer.width === '80') {
            //   // Printer stare konfiguracije nema definiranu širinu
            //   reprint(printer, result.data.full_receipt, 2)
            //   this.loading = false
            //   this.close()
            // } else if (printer.width && printer.width === '58') {
            //   print58(printer, result.data.full_receipt, 2)
            //   this.loading = false
            //   this.close()
            // } else if (printer.width && ['T58', 'T80', 'A4'].includes(printer.width)) {
            //   const docRef = df.doc(`response/${reqId}`)

            //   const unsubscribe = docRef.onSnapshot((doc) => {
            //     if (doc.data()) {
            //       unsubscribe()
            //       if (!doc.data().body || !doc.data().body.document) {
            //         let logError = this.$t('$vuetify.errors.nullReceipt')
            //         if (doc.data().header.error) {
            //           logError = doc.data().header.error
            //         }
            //         this.showMsgBox({
            //           text: logError,
            //           actions: ['cancel'],
            //           cancelBtnText: this.$t('$vuetify.close'),
            //           color: 'error'
            //         })

            //         this.loading = false
            //         return
            //       }

            //       this.openPdfPrint(doc.data().body.document)
            //       this.loading = false

            //       EventBus.$emit('clear-receipt-data')
            //       EventBus.$emit('clear-selection')
            //       this.close()
            //     }
            //   })
            // }
          } else {
            this.hideLoader()
            alert('Dogodila se greška pri izdavanju računa: ' + result.data.header.error)
            this.close()
          }
        })
    },
    close () {
      this.dialogOpen = false
      this.location = {}
      this.multiplier = 0
      this.avansItemName = 'Primljeni predujam'
      this.priceInputEnabled = true
      this.currency = 'EUR'
      this.receiptType = { name: 'Gotovinski račun', value: 'G' }
      this.invoiceDateOpen = false
      this.valueDateOpen = false
      this.deliveryDateOpen = false
      this.associateByName = undefined
      this.associateByOib = undefined
      this.associateAddress = ''
      this.associateCity = ''
      this.associateCountry = ''
      this.associateZip = ''
      this.associate = {
        name: '',
        address: '',
        oib: '',
        zipcode: '',
        city: '',
        country: ''
      }
      this.associateByOib = undefined
      this.associateByName = undefined
      this.associatesPanel = []
    },
    async open (avansFinishData, register = undefined, location = undefined) {
      this.items = avansFinishData.items
      this.discount = avansFinishData.discount
      this.avansFinishData = avansFinishData
      this.dialogOpen = true

      this.priceTaxes = clone(this.taxes)
      this.pdv = this.priceTaxes[0]

      if (state.getCashRegister() && state.getCashRegister().id) {
        this.cashRegisterForTerminal = state.getCashRegister()
      }

      if (register) {
        this.cashRegisterForTerminal = register
      }

      this.cashRegisterNumber = await this.getCashRegisterNumber()

      this.listeners.push(
        df
          .doc(`cash_registers/${this.cashRegisterForTerminal.id}`)
          .onSnapshot((doc) => {
            if (doc && doc.data()) {
              this.registerListener = doc.data()

              if (this.receiptType.value && this.receiptType.value === 'P') {
                this.cashRegisterNumber = this.registerListener.offer_sequence + 1
              } else if (this.receiptType.value && this.receiptType.value === 'V') {
                this.cashRegisterNumber = this.registerListener.virman_sequence + 1
              } else {
                this.cashRegisterNumber = this.registerListener.bill_sequence + 1
              }
              this.calculateReferenceNumber()
            }
          })
      )

      if (state.getPointOfSale() && state.getPointOfSale().id) {
        this.location = state.getPointOfSale()
      }

      if (location) {
        this.location = location
      }

      if (avansFinishData.associate && avansFinishData.associate.payer_name !== '') {
        this.associateByName = avansFinishData.associate.payer_name
        this.associateByOib = avansFinishData.associate.payer_oib
        this.associateAddress = avansFinishData.associate.payer_address
        this.associateZip = avansFinishData.associate.payer_zip_code
        this.associateCity = avansFinishData.associate.payer_city
        this.associateCountry = avansFinishData.associate.payer_country

        this.selectedVies = avansFinishData.associate.selectedVies
        this.selectedCustomerType = avansFinishData.associate.selectedCustomerType
      }

      switch (this.cashRegisterForTerminal.type) {
        case 'MP':
          this.receiptTypes = [
            { name: 'Gotovinski', value: 'G' },
            { name: 'Kartično', value: 'K' },
            { name: 'Ostalo', value: 'O' }
          ]
          this.receiptType = { name: 'Gotovinski', value: 'G' }
          break
        case 'VP':
          this.receiptTypes = [
            { name: 'Transakcijski račun', value: 'T' }]
          this.receiptType = { name: 'Transakcijski račun', value: 'T' }
          break
        case 'ALL':
          this.receiptTypes = [
            { name: 'Transakcijski račun', value: 'T', type: 'R' },
            { name: 'Ponuda', value: 'P', type: 'P' },
            { name: 'Virman', value: 'V', type: 'V' }
          ]

          this.receiptType = { name: 'Transakcijski račun', value: 'T', type: 'R' }

          if (state.getCurrentCompany().certificate !== undefined && state.getCurrentCompany().certificate !== null && state.getCurrentCompany().certificate !== '') {
            this.receiptTypes = [
              { name: 'Gotovinski', value: 'G' },
              { name: 'Kartično', value: 'K' },
              { name: 'Transakcijski račun', value: 'T' },
              { name: 'Ostalo', value: 'O' }
            ]
            this.receiptType = { name: 'Gotovinski', value: 'G' }
          }
          break
      }
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    getEuCountries () {
      const listener = df.doc('project_constants/countries').onSnapshot(doc => {
        this.euCountries = doc.data().eu_countries
      })
      this.listeners.push(listener)
    },

    getCards () {
      const that = this

      df
        .doc(`company_cards/${state.getCurrentCompany().id}`)
        .onSnapshot((doc) => {
          if (
            doc &&
            doc.data() &&
            doc.data().cards &&
            doc.data().cards.length > 0
          ) {
            that.availableCards = doc.data().cards.filter(card => card.active === true)
          }
          that.availableCards = that.availableCards.sort((a, b) => a.name.localeCompare(b.name))
        })
    },
    async getCashRegisterNumber () {
      var cashRegister = await retry(this.getCashRegister, 3)

      if (!cashRegister) {
        return
      }

      return cashRegister.bill_sequence + 1
    },

    async getCashRegister () {
      var registerId = state.getCashRegister().id
      if (this.cashRegisterForTerminal) {
        registerId = this.cashRegisterForTerminal.id
      }

      const response = await df.doc(`cash_registers/${registerId}`).get()
      if (response.data()) {
        return response.data()
      }
    }

  },
  async created () {
    this.cashRegisterNumber = await this.getCashRegisterNumber()
    this.pdv = this.taxes[0]
  },
  mounted () {
    this.getCards()
    this.getEuCountries()

    this.cashRegisterForTerminal = state.getCashRegister()
    this.getMultiplier()
    this.listeners.push(
      df.doc('project_constants/taxes').onSnapshot((doc) => {
        if (doc.data() && doc.data().pdv && doc.data().pdv.length > 0) {
          // this.$refs.addCashRegisterForm.reset()
          this.taxes = doc.data().pdv.map((tax) => {
            return {
              ...tax,
              ...{
                disabled:
                  !state.getCurrentCompany().is_taxed && tax.rate !== 0
              }
            }
          })
        }
      })
    )
    this.selectedCustomerType = 'legal'
    this.selectedVies = 'inside'
  }
}
</script>

<style scoped>
::v-deep .small-radio i {
  font-size: 19px;
}

::v-deep .small-radio label {
  font-size: 14px;
  padding-left: 0px;
  margin-left: -4px;
}

::v-deep .small-radio .v-radio {
  padding: 0px;
}

::v-deep .small-radio [class*="__ripple"] {
  left: 0;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 20px;
}

.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px;
}

.panel-container {
  margin-top: 1rem;
}

.text-wrap {
  width: min-content;
  margin: auto;
}

.panel-cell {
  padding: 0 1px 1px 0;
}

.active {
  background-color: var(--v-accent-darken3);
  color: white;
}

.label {
  font-size: 0.6rem;
  color: white;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
