import { functions } from '@/plugins/firebase'

var clientsToken = null

async function getToken (functionName) {
  var token = null
  switch (functionName) {
    case 'clients':
      token = clientsToken
      break
  }

  if (token && !isTokenExpired(token)) {
    return token
  }

  var getIdentityToken = functions.httpsCallable('getidentitytoken')

  try {
    const response = await getIdentityToken({
      function: process.env.VUE_APP_API_ROOT + functionName
    })

    if (response.data.identity_token) {
      switch (functionName) {
        case 'clients':
          clientsToken = response.data.identity_token
          break
      }

      return response.data.identity_token
    }

    return ''
  } catch (error) {
    console.error('error', error)
  }
}

function decodeToken (token) {
  try {
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    )
    return JSON.parse(jsonPayload)
  } catch (error) {
    console.error('Greška kod dekodiranja tokena:', error)
    return null
  }
}

function isTokenExpired (token) {
  if (!token) return true

  const decoded = decodeToken(token)
  if (!decoded || !decoded.exp) return true

  const now = Math.floor(Date.now() / 1000)
  return decoded.exp < now
}

export { getToken }
