<!-- eslint-disable indent -->
<template>
  <div>
    <v-dialog v-model="dialogOpen"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      max-width="800"
      persistent
      max-height="700"
      style="height: 50px">
      <v-card tile
        color="white"
        class="pb-3">
        <v-card-title class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          ">
          <span class="text-h5"
            style="font-weight: bold">Predujam</span>
        </v-card-title>
        <v-card-text style="padding: 0; margin: 0; overflow-x: hidden">
          <v-container>
            <div class="pa-3"
              style="border: 2px solid grey; margin-bottom: 10px">
              <v-data-table class="invoice-table"
                style="font-size: 5px !important"
                fixed-header
                :headers="headers"
                :items="receipts"
                :items-per-page="-1"
                hide-default-footer>
                <template v-slot:top>
                  <v-toolbar dense
                    flat
                    outlined
                    style="border-left: 0; height: 3.4rem">
                    <v-toolbar-title>Rekapitulacija storno predujma</v-toolbar-title>
                    <v-spacer></v-spacer><v-btn class="btn infoButton"
                      @click="openAvansReceipts"><v-icon>mdi-magnify</v-icon>Pretraži</v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="text-align: center">
                      {{ item.designation.number }}
                    </td>
                    <td style="text-align: center">
                      {{ item.type.payer_name }}
                    </td>
                    <td style="text-align: center">
                      {{ item.type.payer_oib }}
                    </td>
                    <td style="text-align: center">
                      {{ item.issue_date_time | fullDate }}
                    </td>
                    <td style="text-align: center">
                      {{ formatCurrency(item.avansCurrentTaxBase) }}
                    </td>
                    <td style="text-align: center">
                      {{ formatCurrency(item.avansCurrentTaxRate) }}
                    </td>
                    <td style="text-align: center">
                      {{ formatCurrency(item.avansCurrentTaxTotal) }}
                    </td>
                    <td style="text-align: center">
                      {{ formatCurrency(item.avansCurrentTotal) }}
                    </td>
                    <td>
                      <v-icon @click="removeItem(item)">mdi-delete</v-icon>
                    </td>
                  </tr>
                </template>
                <template slot="body.append">
                  <tr style="font-size: 80px">
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th style="text-align: right">Ukupno</th>
                    <th style="text-align: center">
                      {{ formatCurrency(avansTotal) }}
                    </th>
                  </tr>
                </template>
              </v-data-table>
            </div>

            <table style="width: 100%">
              <tr>
                <td style="font-weight: bold; font-size: large">
                  Ukupno bez popusta
                </td>
                <td class="numeric"
                  style="font-weight: bold; font-size: large">
                  {{ showFullTotal() }}
                </td>
                <td></td>
                <td></td>
              </tr>

              <tr v-if="this.avansObjekt.popustNumeric > 0">
                <td style="font-weight: bold; font-size: large">Popust</td>
                <td class="numeric">
                  {{ showFullDiscount() }}
                </td>
                <td></td>
                <td></td>
              </tr>
              <template v-for="pdv in this.avansObjekt.rekapitulacijaPoreza">
                <tr :key="pdv.osnovicaStopa">
                  <td class="pdv">Osnovica ({{ pdv.osnovicaStopa }}%)</td>
                  <td class="numeric">{{ pdv.osnovicaIznos }}</td>
                  <td class="pdv">
                    Storno osnovice ({{ pdv.osnovicaStopa }}%)
                  </td>
                  <td class="numeric">{{ pdv.stornoOsnovice }}</td>
                </tr>
                <tr :key="pdv.osnovicaStopa + 1">
                  <td class="pdv">PDV ({{ pdv.osnovicaStopa }}%)</td>
                  <td class="numeric">
                    {{ pdv.osnovicaPdv }}
                  </td>
                  <td class="pdv">Storno PDV ({{ pdv.osnovicaStopa }}%)</td>
                  <td class="numeric">
                    {{ pdv.stornoOsnovicaPdv }}
                  </td>
                </tr>
              </template>
              <tr>
                <td style="text-align: right; font-weight: bold; font-size: large">
                  Ukupni iznos
                </td>
                <td class="numeric"
                  style="font-weight: bold; font-size: large">
                  {{ showTotal() }}
                </td>
                <td style="text-align: right; font-weight: bold; font-size: large">
                  Storno predujma
                </td>
                <td class="numeric"
                  style="font-weight: bold; font-size: large">
                  {{ showStornoTotal() }}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td style="
                    text-align: right;
                    font-weight: bold;
                    font-size: larger;
                  ">
                  Iznos za naplatu
                </td>
                <td class="numeric"
                  style="font-weight: bold; font-size: large">
                  {{ showTotalToPay() }}
                </td>
              </tr>
            </table>
          </v-container>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn class="btn dangerButton"
            type="submit"
            @click="close()">
            Odustani
          </v-btn>
          <v-btn id="success"
            class="btn successButton"
            type="submit"
            @click="openAvansFinishReceipt()">
            Nastavi
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay v-if="loading"
        :absolute="absolute"
        :opacity="opacity"
        :value="overlay"
        style="z-index: 100">
        <div v-if="loading"
          class="text-center"
          style="
            position: absolute;
            z-index: 101;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          ">
          <v-progress-circular indeterminate
            size="86"
            color="primary">
          </v-progress-circular>
        </div>
      </v-overlay>
    </v-dialog>
    <avans-receipts :associates="associates"
      ref="avansReceipts"
      @connectedReceipts="connectedReceipts"></avans-receipts>
    <avans-finish-receipt :associates="associates"
      ref="avansFinishReceipt"
      @finishReceipt="onFinishReceipt"></avans-finish-receipt>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import applicationSettings from '@/mixins/applicationSettings'
import rules from '@/plugins/rules'
import state from '@/state'
import avansReceipt from '@/mixins/avansReceipt'
import AvansReceipts from '@/modules/cash-register/components/dialogs/avans/AvansReceipts.vue'
import AvansFinishReceipt from '@/modules/cash-register/components/dialogs/avans/AvansFinishReceipt.vue'
import { remapItems } from '@/libs/receiptIssuing/items'
// import axios from 'axios'

export default {
  components: {
    AvansReceipts,
    AvansFinishReceipt
  },
  mixins: [applicationSettings, avansReceipt],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader',
    'confirm',
    'confirmClose',
    'openPdfPrint'
  ],

  data () {
    return {
      dialogOpen: false,
      loading: false,
      lastVisible: {},
      listeners: [],
      multiplier: 0,
      absolute: true,
      opacity: 0.46,
      overlay: true,
      register: null,
      document: null,
      last_balance_timestamp: new Date(new Date().setHours(0, 0, 0, 0)),
      currency: 'EUR',
      rules: {
        req: rules.req(),
        money: rules.money(),
        arrReq: rules.arrReq()
      },
      receipts: [],
      currentReceipt: undefined,
      headers: [
        {
          text: 'Broj predujma',
          align: 'start',
          sortable: false,
          value: 'receipt.designation.number'
        },
        { text: 'Kupac', value: 'receipt.type.payer_name', sortable: false },
        { text: 'OIB', value: 'receipt.type.payer_oib', sortable: false },
        { text: 'Datum', value: 'receipt.issue_date_time', sortable: false },
        { text: 'Osnovica', value: 'receipt.pdv[0].base', sortable: false },
        { text: 'PDV %', value: 'receipt.pdv[0].rate', sortable: false },
        { text: 'PDV', value: 'receipt.pdv[0].total', sortable: false },
        { text: 'Iznos s PDVom', value: 'receipt.total', sortable: false },
        { text: '', value: 'controls', sortable: false }
      ],
      total: 0,
      pdv: 0,
      newReceipt: undefined,
      stornoReceipt: {
        total: 0,
        baseTotal: 0,
        pdvs: [
          { pdv: 0, base: 0, total: 0, rate: 2500 },
          { pdv: 0, base: 0, total: 0, rate: 1300 },
          { pdv: 0, base: 0, total: 0, rate: 500 },
          { pdv: 0, base: 0, total: 0, rate: 0 }
        ]
      },
      guiPdvs: {
        2500: {
          pdvPlus: 0,
          pdvMinus: 0,
          basePlus: 0,
          baseMinus: 0,
          totalPlus: 0,
          totalMinus: 0,
          rate: 2500
        },
        1300: {
          pdvPlus: 0,
          pdvMinus: 0,
          basePlus: 0,
          baseMinus: 0,
          totalPlus: 0,
          totalMinus: 0,
          rate: 1300
        },
        500: {
          pdvPlus: 0,
          pdvMinus: 0,
          basePlus: 0,
          baseMinus: 0,
          totalPlus: 0,
          totalMinus: 0,
          rate: 500
        },
        0: {
          pdvPlus: 0,
          pdvMinus: 0,
          basePlus: 0,
          baseMinus: 0,
          totalPlus: 0,
          totalMinus: 0,
          rate: 0
        }
      },
      avansObjekt: {
        ukupnoBezPopustaSPdv: '',
        ukupnoBezPopusta: '',
        popust: '',
        popustNumeric: 0,
        popustSPdv: '',
        ukupniIznosRacunaSPdv: '',
        ukupniIznosRacuna: '',
        stornoPredujmaSPdv: '',
        stornoPredujma: '',
        iznosZaNaplatu: '',
        iznosZaNaplatuSPdv: '0,00',
        rekapitulacijaPoreza: [
          {
            osnovicaIznos: '0',
            stornoOsnovice: '0',
            osnovicaPdv: '0',
            stornoOsnovicaPdv: '0',
            osnovicaStopa: 25
          }
        ]
      },
      totalDiscount: 0,
      location: undefined,
      items: [],
      discount: undefined,
      initialPayment: '',
      totalDiscountPdv: 0,
      formattedTotalDiscountPdv: 0,
      deliveryDate: 0,
      associate: undefined
    }
  },
  props: {
    cart: {
      type: Object,
      default: () => { }
    },
    associates: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    dialogOpen (val) {
      if (val === false) {
      }
    }
  },
  computed: {
    avansTotal () {
      var total = 0
      if (this.receipts && this.receipts.length > 0) {
        this.receipts.forEach((receipt) => {
          total += receipt.avansCurrentTotal
        })
      }

      return total
    },
    calculateTotalForPayVp () {
      var total = 0
      if (this.newReceipt && this.newReceipt.totalVp > 0) {
        total = this.newReceipt.totalVp
      }
      if (
        this.newReceipt &&
        this.newReceipt.totalVp > 0 &&
        this.stornoReceipt &&
        this.stornoReceipt.baseTotal > 0
      ) {
        total = this.newReceipt.totalVp - this.stornoReceipt.baseTotal
      }

      if (total < 0) {
        total = 0
      }
      return total
    },

    calculateTotalForPayMp () {
      var total = 0
      if (this.newReceipt && this.newReceipt.totalMp > 0) {
        total = this.newReceipt.totalMp
      }
      if (
        this.newReceipt &&
        this.newReceipt.totalMp > 0 &&
        this.stornoReceipt &&
        this.stornoReceipt.total > 0
      ) {
        total = this.newReceipt.totalMp - this.stornoReceipt.total
      }
      if (total < 0) {
        total = 0
      }
      return total
    },
    calculateTotalForPay () {
      var total = 0
      if (this.newReceipt && this.newReceipt.total > 0) {
        total = this.newReceipt.total

        if (this.initialPayment === 'T') {
          total = this.newReceipt.totalMp
        }
      }
      if (
        this.newReceipt &&
        this.newReceipt.total > 0 &&
        this.stornoReceipt &&
        this.stornoReceipt.total > 0
      ) {
        total = this.newReceipt.total - this.stornoReceipt.total

        if (this.initialPayment === 'T') {
          total = this.newReceipt.totalMp - this.stornoReceipt.total
        }
      }

      if (total < 0) {
        total = 0
      }
      return total
    }
  },
  methods: {
    setEmptyGuiPdvs () {
      this.guiPdvs = {
        2500: {
          pdvPlus: 0,
          pdvMinus: 0,
          basePlus: 0,
          baseMinus: 0,
          totalPlus: 0,
          totalMinus: 0,
          rate: 2500
        },
        1300: {
          pdvPlus: 0,
          pdvMinus: 0,
          basePlus: 0,
          baseMinus: 0,
          totalPlus: 0,
          totalMinus: 0,
          rate: 1300
        },
        500: {
          pdvPlus: 0,
          pdvMinus: 0,
          basePlus: 0,
          baseMinus: 0,
          totalPlus: 0,
          totalMinus: 0,
          rate: 500
        },
        0: {
          pdvPlus: 0,
          pdvMinus: 0,
          basePlus: 0,
          baseMinus: 0,
          totalPlus: 0,
          totalMinus: 0,
          rate: 0
        }
      }
    },
    setEmptyAvansObjekt () {
      this.avansObjekt = {
        ukupnoBezPopustaSPdv: '',
        ukupnoBezPopusta: '',
        popust: '',
        popustNumeric: 0,
        popustSPdv: '',
        ukupniIznosRacunaSPdv: '',
        ukupniIznosRacuna: '',
        stornoPredujmaSPdv: '',
        stornoPredujma: '',
        iznosZaNaplatu: '',
        iznosZaNaplatuSPdv: '0,00',
        rekapitulacijaPoreza: [
          {
            osnovicaIznos: '0',
            stornoOsnovice: '0',
            osnovicaPdv: '0',
            stornoOsnovicaPdv: '0',
            osnovicaStopa: 25
          }
        ]
      }
    },
    setAvansObjekt () {
      this.setEmptyAvansObjekt()

      this.avansObjekt.popustNumeric = this.totalDiscount
      this.avansObjekt.popust = (this.totalDiscount / 100)
        .toFixed(2)
        .replace('.', ',')
      this.avansObjekt.stornoPredujma = this.stornoReceipt?.baseTotal
        ? ((this.stornoReceipt.baseTotal / 100) * -1)
          .toFixed(2)
          .replace('.', ',')
        : '0,00'
      this.avansObjekt.stornoPredujmaSPdv = this.stornoReceipt?.total
        ? ((this.stornoReceipt.total / 100) * -1).toFixed(2).replace('.', ',')
        : '0,00'
      this.avansObjekt.popustSPdv = (this.totalDiscountPdv / 100)
        .toFixed(2)
        .replace('.', ',')

      this.avansObjekt.ukupnoBezPopusta = clone(
        this.newReceipt.formattedFullTotalVp
      )
      this.avansObjekt.ukupnoBezPopustaSPdv = clone(
        this.newReceipt.formattedFullTotalMp
      )
      this.avansObjekt.ukupniIznosRacuna = clone(
        this.newReceipt.formattedTotalVp
      )
      this.avansObjekt.ukupniIznosRacunaSPdv = clone(
        this.newReceipt.formattedTotalMp
      )

      this.avansObjekt.iznosZaNaplatu = (this.calculateTotalForPayVp / 100)
        .toFixed(2)
        .replace('.', ',')
      this.avansObjekt.iznosZaNaplatuSPdv = (this.calculateTotalForPayMp / 100)
        .toFixed(2)
        .replace('.', ',')

      this.avansObjekt.rekapitulacijaPoreza = []

      if (this.guiPdvs[2500].basePlus > 0) {
        this.avansObjekt.rekapitulacijaPoreza.push({
          osnovicaIznos: (this.guiPdvs[2500].basePlus / 100)
            .toFixed(2)
            .replace('.', ','),
          stornoOsnovice: ((this.guiPdvs[2500].baseMinus / 100) * -1)
            .toFixed(2)
            .replace('.', ','),
          osnovicaPdv: (this.guiPdvs[2500].pdvPlus / 100)
            .toFixed(2)
            .replace('.', ','),
          stornoOsnovicaPdv: ((this.guiPdvs[2500].pdvMinus / 100) * -1)
            .toFixed(2)
            .replace('.', ','),
          osnovicaStopa: 25
        })
      }

      if (this.guiPdvs[1300].basePlus > 0) {
        this.avansObjekt.rekapitulacijaPoreza.push({
          osnovicaIznos: (this.guiPdvs[1300].basePlus / 100)
            .toFixed(2)
            .replace('.', ','),
          stornoOsnovice: ((this.guiPdvs[1300].baseMinus / 100) * -1)
            .toFixed(2)
            .replace('.', ','),
          osnovicaPdv: (this.guiPdvs[1300].pdvPlus / 100)
            .toFixed(2)
            .replace('.', ','),
          stornoOsnovicaPdv: ((this.guiPdvs[1300].pdvMinus / 100) * -1)
            .toFixed(2)
            .replace('.', ','),
          osnovicaStopa: 13
        })
      }

      if (this.guiPdvs[500].basePlus > 0) {
        this.avansObjekt.rekapitulacijaPoreza.push({
          osnovicaIznos: (this.guiPdvs[500].basePlus / 100)
            .toFixed(2)
            .replace('.', ','),
          stornoOsnovice: ((this.guiPdvs[500].baseMinus / 100) * -1)
            .toFixed(2)
            .replace('.', ','),
          osnovicaPdv: (this.guiPdvs[500].pdvPlus / 100)
            .toFixed(2)
            .replace('.', ','),
          stornoOsnovicaPdv: ((this.guiPdvs[500].pdvMinus / 100) * -1)
            .toFixed(2)
            .replace('.', ','),
          osnovicaStopa: 5
        })
      }

      if (this.guiPdvs[0].basePlus > 0) {
        this.avansObjekt.rekapitulacijaPoreza.push({
          osnovicaIznos: (this.guiPdvs[0].basePlus / 100)
            .toFixed(2)
            .replace('.', ','),
          stornoOsnovice: ((this.guiPdvs[0].baseMinus / 100) * -1)
            .toFixed(2)
            .replace('.', ','),
          osnovicaPdv: (this.guiPdvs[0].pdvPlus / 100)
            .toFixed(2)
            .replace('.', ','),
          stornoOsnovicaPdv: ((this.guiPdvs[0].pdvMinus / 100) * -1)
            .toFixed(2)
            .replace('.', ','),
          osnovicaStopa: 0
        })
      }
    },
    showTotalToPay () {
      if (this.initialPayment === 'T') {
        return (
          this.avansObjekt.iznosZaNaplatu +
          ' (' +
          this.avansObjekt.iznosZaNaplatuSPdv +
          ')'
        )
      } else {
        return this.avansObjekt.iznosZaNaplatuSPdv
      }
    },
    showStornoTotal () {
      if (this.initialPayment === 'T') {
        return (
          this.avansObjekt.stornoPredujma +
          ' (' +
          this.avansObjekt.stornoPredujmaSPdv +
          ') '
        )
      } else {
        return this.avansObjekt.stornoPredujmaSPdv
      }
    },
    showFullDiscount () {
      if (this.newReceipt && this.initialPayment !== 'T') {
        return this.avansObjekt.popustSPdv
      }

      if (this.newReceipt && this.initialPayment === 'T') {
        return (
          this.avansObjekt.popust + ' (' + this.avansObjekt.popustSPdv + ') '
        )
      }
    },
    showFullTotal () {
      if (this.initialPayment !== 'T') {
        return this.avansObjekt.ukupnoBezPopustaSPdv
      }

      if (this.initialPayment === 'T') {
        return (
          this.avansObjekt.ukupnoBezPopusta +
          ' (' +
          this.avansObjekt.ukupnoBezPopustaSPdv +
          ') '
        )
      }
    },
    showTotal () {
      if (this.newReceipt && this.initialPayment !== 'T') {
        return this.avansObjekt.ukupniIznosRacunaSPdv
      }

      if (this.newReceipt && this.initialPayment === 'T') {
        return (
          this.avansObjekt.ukupniIznosRacuna +
          ' (' +
          this.avansObjekt.ukupniIznosRacunaSPdv +
          ') '
        )
      }
    },
    onFinishReceipt () {
      this.close()
    },
    removeItem (receipt) {
      var oldRcpts = clone(this.receipts)

      oldRcpts = oldRcpts.filter((rcpt) => rcpt.id !== receipt.id)
      var newTemp = clone(this.newReceipt)

      this.stornoReceipt.total = 0
      this.stornoReceipt.baseTotal = 0
      this.stornoReceipt.pdvs = [
        { pdv: 0, base: 0, total: 0, rate: 2500 },
        { pdv: 0, base: 0, total: 0, rate: 1300 },
        { pdv: 0, base: 0, total: 0, rate: 500 },
        { pdv: 0, base: 0, total: 0, rate: 0 }
      ]

      this.guiPdvs[2500].baseMinus = 0
      this.guiPdvs[2500].pdvMinus = 0
      this.guiPdvs[2500].totalMinus = 0

      this.guiPdvs[1300].baseMinus = 0
      this.guiPdvs[1300].pdvMinus = 0
      this.guiPdvs[1300].totalMinus = 0

      this.guiPdvs[500].baseMinus = 0
      this.guiPdvs[500].pdvMinus = 0
      this.guiPdvs[500].totalMinus = 0

      this.guiPdvs[0].baseMinus = 0
      this.guiPdvs[0].pdvMinus = 0
      this.guiPdvs[0].totalMinus = 0

      this.receipts = []

      if (oldRcpts && oldRcpts.length > 0) {
        oldRcpts.forEach((receipt) => {
          var rcpt = clone(receipt)

          newTemp.pdvs.forEach((pdv, k) => {
            if (rcpt.avansCurrentTaxRate === pdv.rate) {
              if (rcpt.avansCurrentTotal > pdv.total) {
                this.stornoReceipt.total += pdv.total

                this.stornoReceipt.pdvs.forEach((spdv, i) => {
                  if (spdv.rate === pdv.rate) {
                    this.stornoReceipt.baseTotal += pdv.base

                    this.stornoReceipt.pdvs[i].pdv += pdv.pdv
                    this.stornoReceipt.pdvs[i].base += pdv.base
                    this.stornoReceipt.pdvs[i].total += pdv.total

                    this.guiPdvs[pdv.rate].baseMinus += pdv.base
                    this.guiPdvs[pdv.rate].pdvMinus += pdv.pdv
                    this.guiPdvs[pdv.rate].totalMinus += pdv.total
                  }
                })

                rcpt.total = pdv.total
                rcpt.pdv[0].base = pdv.base
                rcpt.pdv[0].total = pdv.pdv
              } else {
                this.stornoReceipt.total += receipt.avansCurrentTotal

                this.stornoReceipt.pdvs.forEach((spdv, i) => {
                  if (spdv.rate === pdv.rate) {
                    this.stornoReceipt.baseTotal += receipt.avansCurrentTaxBase

                    this.stornoReceipt.pdvs[i].pdv +=
                      receipt.avansCurrentTaxTotal
                    this.stornoReceipt.pdvs[i].base +=
                      receipt.avansCurrentTaxBase
                    this.stornoReceipt.pdvs[i].total +=
                      receipt.avansCurrentTotal

                    this.guiPdvs[pdv.rate].baseMinus +=
                      receipt.avansCurrentTaxBase
                    this.guiPdvs[pdv.rate].pdvMinus +=
                      receipt.avansCurrentTaxTotal
                    this.guiPdvs[pdv.rate].totalMinus +=
                      receipt.avansCurrentTotal
                  }
                })
              }
            }
          })

          this.stornoReceipt.pdvs.sort((a, b) => b.rate - a.rate) // b - a for reverse sort

          this.receipts.push(rcpt)
        })
      }
      this.setAvansObjekt()
    },
    formatCurrency (item, divider = 100) {
      return this.$options.filters
        .money(item, divider, this.currency)
        .replace('€', '')
    },
    openAvansFinishReceipt () {
      // var generalDiscount = this.discount && this.discount.amount > 0 ? this.discount.amount : 0

      var avansFinishData = {
        discount: {
          type: 'amount',
          name: 'AVANS-STORNO-RECEIPT',
          amount: this.stornoReceipt.total
        },
        generalDiscount: this.totalDiscount,
        items: this.items,
        newReceipt: this.newReceipt,
        stornoReceipt: this.stornoReceipt,
        avansReceipts: this.receipts,
        total: this.calculateTotalForPay,
        fullTotal: this.newReceipt.fullTotal,
        totalDiscountPdv: this.totalDiscountPdv,
        deliveryDate: this.deliveryDate
      }

      if (this.associate) {
        avansFinishData.associate = this.associate
      }

      if (this.discount && this.discount.amount > 0) {
        avansFinishData.discount.amount += this.discount.amount
      }

      this.$refs.avansFinishReceipt.open(
        avansFinishData,
        this.register,
        this.location
      )
      // this.close()
    },
    async setNewReceiptData (req) {
      if (this.items && this.items.length > 0) {
        const data = {
          currency: 'EUR',
          items: clone(this.items),
          registerType: state.getCashRegister().type,
          title: ''
        }

        var method = 'G'
        const remappedItems = await remapItems(data, method)

        var receipt = {
          total: 0,
          totalMp: undefined,
          totalVp: undefined,
          pdvs: [
            { pdv: 0, base: 0, total: 0, rate: 0 },
            // eslint-disable-next-line indent
            { pdv: 0, base: 0, total: 0, rate: 500 },
            { pdv: 0, base: 0, total: 0, rate: 1300 },
            { pdv: 0, base: 0, total: 0, rate: 2500 }
          ],
          fullTotal: 0
        }

        this.guiPdvs[2500].basePlus = 0
        this.guiPdvs[2500].pdvPlus = 0
        this.guiPdvs[2500].totalPlus = 0

        this.guiPdvs[1300].basePlus = 0
        this.guiPdvs[1300].pdvPlus = 0
        this.guiPdvs[1300].totalPlus = 0

        this.guiPdvs[500].basePlus = 0
        this.guiPdvs[500].pdvPlus = 0
        this.guiPdvs[500].totalPlus = 0

        this.guiPdvs[0].basePlus = 0
        this.guiPdvs[0].pdvPlus = 0
        this.guiPdvs[0].totalPlus = 0

        if (req.totalMp) {
          receipt.totalMp = req.totalMp
          receipt.formattedTotalMp = (req.totalMp / 100)
            .toFixed(2)
            .replace('.', ',')
        }

        if (req.totalVp) {
          receipt.totalVp = req.totalVp
          receipt.formattedTotalVp = (req.totalVp / 100)
            .toFixed(2)
            .replace('.', ',')
        }

        if (req.fullTotalMp) {
          receipt.fullTotalMp = req.fullTotalMp
          receipt.formattedFullTotalMp = (req.fullTotalMp / 100)
            .toFixed(2)
            .replace('.', ',')
        }

        if (req.fullTotalVp) {
          receipt.fullTotalVp = req.fullTotalVp
          receipt.formattedFullTotalVp = (req.fullTotalVp / 100)
            .toFixed(2)
            .replace('.', ',')
        }

        var itemDiscount = 0
        remappedItems.forEach((it) => {
          var oneItemPrice = it.single_item_price

          if (it.discounted_price) {
            oneItemPrice = it.discounted_price
          }

          if (it.price) {
            receipt.total += it.price
          }

          receipt.pdvs.forEach((pdvObject, i) => {
            if (pdvObject.rate === it.pdv.rate) {
              // this.items.forEach((item, i) => {
              //   if (item.prices[this.currency].base === item.prices[this.currency].price) {
              //     item.prices[this.currency].price = item.prices[this.currency].base * (1 + (item.prices[this.currency].taxes[0].rate / 10000))
              //   }
              // })

              var base = oneItemPrice / (1 + it.pdv.rate / 10000)
              var pdv = oneItemPrice - base
              receipt.pdvs[i].base += Math.round(base * (it.amt / 1000))
              receipt.pdvs[i].pdv += Math.round(pdv * (it.amt / 1000))
              receipt.pdvs[i].total = it.price
            }
          })

          if (it.discount && it.discount.amount > 0) {
            itemDiscount += it.discount.amount * (it.amt / 1000)
          }
        })

        if (req.fullTotalMp) {
          receipt.fullTotal = req.fullTotalMp
        }

        if (req.pdvs) {
          receipt.pdvs = []

          Object.keys(req.pdvs).forEach((key) => {
            receipt.pdvs.push(req.pdvs[key])
          })
        }

        if (this.discount && this.discount.amount > 0) {
          this.totalDiscount = this.discount.amount
          receipt.total -= this.discount.amount
          var totalRemove = this.totalDiscount / 100

          receipt.pdvs.sort((a, b) => b.rate - a.rate) // b - a for reverse sort

          receipt.pdvs.forEach((p, pkey) => {
            if (p.base > 0 && p.pdv > 0) {
              let totalPrice = p.total / 100

              if (totalPrice >= 0) {
                let removedAllPossible = false
                let remove = totalRemove

                // kreni unazad, uzmi ukupni iznos za skidanje i probaj skinuti
                for (
                  var j = totalRemove;
                  j > 0 && removedAllPossible === false;
                  j--
                ) {
                  if (totalPrice - j >= 0) {
                    removedAllPossible = true
                    remove = j
                  }
                }

                if (removedAllPossible === true) {
                  totalPrice -= remove
                  totalRemove -= remove
                }
              }

              receipt.pdvs[pkey].total = totalPrice * 100
              receipt.pdvs[pkey].base = parseInt(
                parseFloat(receipt.pdvs[pkey].total) /
                (1 + parseFloat(receipt.pdvs[pkey].rate) / 10000)
              )
              receipt.pdvs[pkey].pdv =
                receipt.pdvs[pkey].total - receipt.pdvs[pkey].base
            }
          })
        }

        receipt.pdvs.forEach((pdv) => {
          this.guiPdvs[pdv.rate].basePlus += pdv.base
          this.guiPdvs[pdv.rate].pdvPlus += pdv.pdv
          this.guiPdvs[pdv.rate].totalPlus += pdv.total
        })

        this.totalDiscount += itemDiscount
        this.newReceipt = receipt
        this.setAvansObjekt()
      }
    },
    connectedReceipts (receipts) {
      this.receipts = []
      var newTemp = clone(this.newReceipt)

      this.stornoReceipt = {
        total: 0,
        baseTotal: 0,
        pdvs: [
          { pdv: 0, base: 0, total: 0, rate: 2500 },
          { pdv: 0, base: 0, total: 0, rate: 1300 },
          { pdv: 0, base: 0, total: 0, rate: 500 },
          { pdv: 0, base: 0, total: 0, rate: 0 }
        ]
      }

      this.guiPdvs[2500].baseMinus = 0
      this.guiPdvs[2500].pdvMinus = 0
      this.guiPdvs[2500].totalMinus = 0

      this.guiPdvs[1300].baseMinus = 0
      this.guiPdvs[1300].pdvMinus = 0
      this.guiPdvs[1300].totalMinus = 0

      this.guiPdvs[500].baseMinus = 0
      this.guiPdvs[500].pdvMinus = 0
      this.guiPdvs[500].totalMinus = 0

      this.guiPdvs[0].baseMinus = 0
      this.guiPdvs[0].pdvMinus = 0
      this.guiPdvs[0].totalMinus = 0

      // if (receipts && receipts.length > 0) {
      //   receipts.forEach(receipt => {
      //     var rcpt = clone(receipt)

      //     newTemp.pdvs.forEach((pdv, k) => {
      //       if (rcpt.avansCurrentTaxRate === pdv.rate) {
      //         if (rcpt.avansCurrentTotal > pdv.total) {
      //           this.stornoReceipt.total += pdv.total

      //           this.stornoReceipt.pdvs.forEach((spdv, i) => {
      //             if (spdv.rate === pdv.rate) {
      //               this.stornoReceipt.baseTotal += pdv.base

      //               this.stornoReceipt.pdvs[i].pdv += pdv.pdv
      //               this.stornoReceipt.pdvs[i].base += pdv.base
      //               this.stornoReceipt.pdvs[i].total += pdv.total

      //               this.guiPdvs[pdv.rate].baseMinus += pdv.base
      //               this.guiPdvs[pdv.rate].pdvMinus += pdv.pdv
      //               this.guiPdvs[pdv.rate].totalMinus += pdv.total
      //             }
      //           })

      //           rcpt.total = pdv.total
      //           rcpt.pdv[0].base = pdv.base
      //           rcpt.pdv[0].total = pdv.pdv
      //         } else {
      //           this.stornoReceipt.total += receipt.avansCurrentTotal

      //           this.stornoReceipt.pdvs.forEach((spdv, i) => {
      //             if (spdv.rate === pdv.rate) {
      //               this.stornoReceipt.baseTotal += receipt.avansCurrentTaxBase

      //               this.stornoReceipt.pdvs[i].pdv += receipt.avansCurrentTaxTotal
      //               this.stornoReceipt.pdvs[i].base += receipt.avansCurrentTaxBase
      //               this.stornoReceipt.pdvs[i].total += receipt.avansCurrentTotal

      //               this.guiPdvs[pdv.rate].baseMinus += receipt.avansCurrentTaxBase
      //               this.guiPdvs[pdv.rate].pdvMinus += receipt.avansCurrentTaxTotal
      //               this.guiPdvs[pdv.rate].totalMinus += receipt.avansCurrentTotal
      //             }
      //           })
      //         }
      //       }
      //     })

      //     this.stornoReceipt.pdvs.sort((a, b) => b.rate - a.rate) // b - a for reverse sort
      //     this.receipts.push(rcpt)
      //   })

      //   this.setAvansObjekt()
      // }

      if (receipts && receipts.length > 0) {
        newTemp.pdvs.forEach((pdv, k) => {
          var clonePdv = clone(pdv)

          receipts.forEach((receipt) => {
            var rcpt = clone(receipt)

            if (
              clonePdv.total > 0 &&
              rcpt.avansCurrentTaxRate === clonePdv.rate
            ) {
              if (rcpt.avansCurrentTotal > clonePdv.total) {
                this.stornoReceipt.total += clonePdv.total

                this.stornoReceipt.pdvs.forEach((spdv, i) => {
                  if (spdv.rate === clonePdv.rate) {
                    this.stornoReceipt.baseTotal += clonePdv.base

                    this.stornoReceipt.pdvs[i].pdv += clonePdv.pdv
                    this.stornoReceipt.pdvs[i].base += clonePdv.base
                    this.stornoReceipt.pdvs[i].total += clonePdv.total

                    this.guiPdvs[clonePdv.rate].baseMinus += clonePdv.base
                    this.guiPdvs[clonePdv.rate].pdvMinus += clonePdv.pdv
                    this.guiPdvs[clonePdv.rate].totalMinus += clonePdv.total
                  }
                })

                rcpt.total = clonePdv.total
                rcpt.pdv[0].base = clonePdv.base
                rcpt.pdv[0].total = clonePdv.pdv

                clonePdv.total = 0
              } else {
                this.stornoReceipt.total += receipt.avansCurrentTotal

                clonePdv.total -= receipt.avansCurrentTotal
                clonePdv.base -= receipt.avansCurrentTaxBase
                clonePdv.pdv -= receipt.avansCurrentTaxTotal

                this.stornoReceipt.pdvs.forEach((spdv, i) => {
                  if (spdv.rate === clonePdv.rate) {
                    this.stornoReceipt.baseTotal += receipt.avansCurrentTaxBase

                    this.stornoReceipt.pdvs[i].base +=
                      receipt.avansCurrentTaxBase
                    this.stornoReceipt.pdvs[i].pdv +=
                      receipt.avansCurrentTaxTotal
                    this.stornoReceipt.pdvs[i].total +=
                      receipt.avansCurrentTotal

                    this.guiPdvs[pdv.rate].baseMinus +=
                      receipt.avansCurrentTaxBase
                    this.guiPdvs[pdv.rate].pdvMinus +=
                      receipt.avansCurrentTaxTotal
                    this.guiPdvs[pdv.rate].totalMinus +=
                      receipt.avansCurrentTotal
                  }
                })
              }

              this.receipts.push(rcpt)
            }
          })
        })
        this.stornoReceipt.pdvs.sort((a, b) => b.rate - a.rate) // b - a for reverse sort

        this.setAvansObjekt()
      }
    },

    openAvansReceipts () {
      this.$refs.avansReceipts.open(this.register)
    },
    submit () { },
    close () {
      this.receipts = []
      this.currentReceipt = undefined
      this.total = 0
      this.totalDiscount = 0
      this.pdv = 0
      this.newReceipt = undefined
      this.stornoReceipt = {
        total: 0,
        pdvs: [
          { pdv: 0, base: 0, total: 0, rate: 0 },
          { pdv: 0, base: 0, total: 0, rate: 500 },
          { pdv: 0, base: 0, total: 0, rate: 1300 },
          { pdv: 0, base: 0, total: 0, rate: 2500 }
        ],
        base: 0
      }
      this.dialogOpen = false
    },
    open (data) {
      this.setEmptyGuiPdvs()
      this.setEmptyAvansObjekt()
      this.items = clone(data.items)

      if (data.initialPayment) {
        this.initialPayment = data.initialPayment
      }

      if (data.discount) {
        this.discount = data.discount
      }

      if (data.totalDiscountPdv) {
        this.totalDiscountPdv = data.totalDiscountPdv
      }

      if (data.formattedTotalDiscountPdv) {
        this.formattedTotalDiscountPdv = data.formattedTotalDiscountPdv
      }

      if (state.getCashRegister() && state.getCashRegister().id) {
        this.register = state.getCashRegister()
      }

      if (data.cashRegister) {
        this.register = data.cashRegister
      }

      if (state.getPointOfSale() && state.getPointOfSale().id) {
        this.location = state.getPointOfSale()
      }

      if (data.location) {
        this.location = data.location
      }

      if (data.deliveryDate) {
        this.deliveryDate = data.deliveryDate
      }

      if (data.associate) {
        this.associate = data.associate
      }

      this.setNewReceiptData(data)
      this.dialogOpen = true
    },

    navigateToFlow () {
      this.$router.push({
        name: 'Home',
        params: {}
      })
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot((doc) => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  },
  async created () {
    this.cashRegisterNumber = await this.getCashRegisterNumber()
  },
  mounted () {
    this.getMultiplier()
    this.listeners.push(
      df.doc('project_constants/taxes').onSnapshot((doc) => {
        if (doc.data() && doc.data().pdv && doc.data().pdv.length > 0) {
          // this.$refs.addCashRegisterForm.reset()
          this.taxes = doc.data().pdv.map((tax) => {
            return {
              ...tax,
              ...{
                disabled: !state.getCurrentCompany().is_taxed && tax.rate !== 0
              }
            }
          })
        }
      })
    )
  }
}
</script>

<style scoped>
.numeric {
  text-align: right !important;
}

.pdv {
  padding-left: 20px !important;
}

.v-dialog__content>>>.my-custom-dialog {
  position: absolute;
  top: 50px;
  border-radius: 25px !important;
}

.panel-container {
  margin-top: 1rem;
}

.text-wrap {
  width: min-content;
  margin: auto;
}

.panel-cell {
  padding: 0 1px 1px 0;
}

.active {
  background-color: var(--v-accent-darken3);
  color: white;
}

.label {
  font-size: 0.6rem;
  color: white;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}

tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
