<template>
  <v-dialog v-model="dialogOpen"
    v-if="dialogOpen"
    persistent
    max-width="630"
    @keydown.esc="close">
    <v-card class="justify-center overflow-auto"
      height="615px">
      <v-form ref="accountForm"
        @submit.prevent="finish">
        <v-card-title class="h6 mx-0">
          <h2>{{ $t("$vuetify.accountDetailsTitle") }}</h2>
          <v-spacer></v-spacer>
          <span :style="{ color: saveAssociateEnabled ? 'black' : '#d9d9d9' }"
            style="font-size: 1rem">
            {{ $t("$vuetify.formDetails.saveAssociate") }}
          </span>
          <v-checkbox class="ml-2"
            :disabled="!saveAssociateEnabled"
            v-model="doSaveAccount"></v-checkbox>
          <v-icon large
            @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pb-0 px-0">
          <v-container>
            <v-row class="input-row">
              <v-col>
                <v-combobox clearable
                  @focus="showKbd"
                  data-layout="normalAduro"
                  :label="$t('$vuetify.formDetails.oib')"
                  v-model="payerOib"
                  :search-input.sync="payerOib"
                  :items="payer ? payer.oibs : []"
                  validate-on-blur
                  @keydown.enter="finishPayment"
                  class="uppercased-input">
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item @keydown.enter.stop=""
                      v-on="on"
                      v-bind="attrs">{{ item }}</v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row class="input-row">
              <v-col>
                <v-combobox @focus="showKbd"
                  data-layout="normalAduro"
                  :label="$t('$vuetify.formDetails.name')"
                  :placeholder="$t('$vuetify.filterPlaceholderLabel')"
                  v-model="payer"
                  :loading="loading"
                  :items="associates"
                  return-object
                  item-text="name"
                  item-value="name"
                  @change="setForm(true)"
                  :search-input.sync="payerName"
                  @update:search-input="querySelections"
                  hide-no-data
                  clearable
                  @click:clear="setForm(false)"
                  :rules="[rules.req]"
                  @keydown.enter="finishPayment"
                  class="uppercased-input">
                  <template v-slot:item="{ item, on, attrs }">
                    <v-list-item class="uppercased-input"
                      @keydown.enter.stop=""
                      v-on="on"
                      v-bind="attrs">{{ item.name }}</v-list-item>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
            <v-row class="input-row">
              <v-col>
                <v-text-field @focus="showKbd"
                  data-layout="normalAduro"
                  class="uppercased-input"
                  v-model="payerAddress"
                  :label="$t('$vuetify.formDetails.address')"
                  :rules="[rules.req]"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="input-row">
              <v-col>
                <v-text-field @focus="showKbd"
                  data-layout="normalAduro"
                  v-model="payerCity"
                  :label="$t('$vuetify.formDetails.city')"
                  :rules="[rules.req]"
                  class="uppercased-input"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="input-row">
              <v-col>
                <v-text-field @focus="showKbd"
                  data-layout="normalAduro"
                  :label="$t('$vuetify.formDetails.zipCode')"
                  v-model="payerZip"
                  :rules="[rules.req]"
                  class="uppercased-input"></v-text-field>
              </v-col>
            </v-row>
            <v-row class="input-row">
              <v-col>
                <v-select @focus="showKbd"
                  data-layout="normalAduro"
                  disabled
                  :items="countries"
                  :label="$t('$vuetify.formDetails.country')"
                  v-model="payerCountry"
                  item-text="country"
                  item-value="country"
                  :rules="[rules.req]"
                  class="uppercased-input"></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="mx-5 mb-4">
          <v-spacer></v-spacer>
          <v-row style="height: 75px"
            class="d-flex justify-end align-end">
            <v-btn class="white--text okButton"
              id="finishPayment"
              type="submit">
              {{ $t("$vuetify.finishPayment") }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-container class="kbd"
      v-if="!$vuetify.breakpoint.smAndDown"
      ref="keyboard"
      v-show="oskVisible"
      :style="{ 'max-width': width }">
      <div class="kbd-header"></div>
      <vue-touch-keyboard :options="oskOptions"
        :layout="layout"
        :cancel="hideKbd"
        :accept="accept"
        :input="input" />
    </v-container>
  </v-dialog>
</template>

<script>
import state from '@/state'
import rules from '@/plugins/rules'
import countries from '@/mixins/countries'
import { clone, toISOLocal } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import { printerInit } from '@/libs/bixolon/printer'
import onScreenKeyboard from '@/mixins/onScreenKeyboard'
import { callAssociates } from '@/api/associates'

export default {
  mixins: [countries, onScreenKeyboard],

  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'openPdfPrint'],

  data () {
    return {
      templatesDialogOpen: false,
      dialogOpen: false,
      loading: false,
      listeners: [],
      defaultAssociates: [],
      associates: [],
      fiscalize: false,
      payerName: undefined,
      payer: undefined,
      payerAddress: undefined,
      payerCity: undefined,
      payerZip: undefined,
      payerCountry: undefined,
      payerOib: undefined,
      rules: {
        req: rules.req(),
        oib: rules.oib()
      },
      inEu: false,
      languages: [],
      language: {},
      note: undefined,
      receiptNumber: 1,
      offer_sequence: undefined,
      bill_sequence: undefined,
      last_receipt: undefined,
      minIssueDate: undefined,
      flag: undefined,
      doSaveAccount: false,
      saveAssociateEnabled: true,
      submitting: false,
      takenOibs: [],
      receipt: {}
    }
  },

  props: {
    data: {
      type: Object,
      default: () => { }
    }
  },

  watch: {
    storeReceiptNumber (nv) {
      this.receiptNumber = nv + 1
    },
    payerOib (nv) {
      if (nv && nv.length === 11) {
        if (this.takenOibs.includes(nv)) {
          this.doSaveAccount = false
          this.saveAssociateEnabled = false
        } else {
          this.saveAssociateEnabled = true
        }
      }
    }
  },

  computed: {
    storeReceiptNumber () {
      if (this.receiptType && this.receiptType.value === 'P') {
        return this.offer_sequence
      } else {
        return this.bill_sequence
      }
    }
  },

  methods: {
    open (receipt) {
      this.receipt = { ...receipt }

      this.payerAddress = this.receipt.type.payer_address
      this.payerCity = this.receipt.type.payer_city
      this.payerCountry = this.receipt.type.payer_country
      this.payer = this.receipt.type.payer_name
      this.payerOib = this.receipt.type.payer_oib
      this.payerZip = this.receipt.type.payer_zip_code

      this.dialogOpen = true
    },
    close () {
      this.$refs.accountForm.reset()
      this.dialogOpen = false
    },
    finish () {
      this.changeReceiptType()
      this.$refs.accountForm.reset()
    },

    async changeReceiptType () {
      const me = this

      var type = {
        payer_address: this.payerAddress,
        payer_city: this.payerCity,
        payer_country: this.payerCountry,
        payer_name: this.payerName,
        payer_oib: this.payerOib,
        payer_zip_code: this.payerZip,
        flag: 'R'
      }

      df.doc(`receipts/${state.getCurrentCompany().id}/receipts/${this.receipt.id}`).update({
        type: type
      })
        .then(() => {
          console.info('Receipt succesfully updated')
          this.$emit('refresh', type)
          this.close()
        })
        .catch(() => {
          me.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        })
    },

    setDefaults () {
      this.fiscalize = true
      this.languages = [
        { name: this.$t('$vuetify.receiptLanguages.hr'), value: 'hr' },
        { name: this.$t('$vuetify.receiptLanguages.en'), value: 'en' }
      ]
      this.language = this.languages[0]
      this.note = ''
      this.flag = 'R'
    },

    setForm (data = true) {
      if (data) {
        this.referenceNumber = ''
        if (this.payer && typeof this.payer === 'object') {
          // this.payerCountry = this.payer.country
          this.payerCountry = 'Hrvatska'
          this.payerOib = this.payer.oibs ? this.payer.oibs[0] : ''
          this.payerAddress = this.payer.address
          this.payerCity = this.payer.city
          this.payerZip = this.payer.zip_code

          this.saveAssociateEnabled = false
        }
      } else {
        this.referenceNumber = ''
        this.associates = clone(this.defaultAssociates)
        this.saveAssociateEnabled = true
        this.$nextTick(() => {
          // this.$refs.accountForm.reset()
        })
      }
    },

    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.paymentModels = doc.data().payment_models
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },

    getEuCountries () {
      const listener = df.doc('project_constants/countries').onSnapshot(doc => {
        this.euCountries = doc.data().eu_countries
      })
      this.listeners.push(listener)
    },

    querySelections () {
      if (this.payerName) {
        this.associates = this.defaultAssociates.filter((associate) =>
          associate.name.toLowerCase().includes(this.payerName.toLowerCase())
        )
      } else {
        this.associates = clone(this.defaultAssociates)
      }
    },

    backwardStep () {
      this.setForm(false)
      this.$emit('closeR1Dialog')
    },

    dateTimeToUnixTimestamp (dt) {
      return Math.floor(new Date(toISOLocal(dt)).getTime() / 1000)
    },

    saveAssociate () {
      const reqId = uuidv4()
      const oibs = [this.payerOib]
      const payload = {
        action: {
          operation: 'set',
          entity: 'associate',
          params: {
            company_id: state.getCurrentCompany().id,
            name: this.payerName.toLowerCase(),
            oibs: oibs,
            address: this.payerAddress.toLowerCase(),
            city: this.payerCity.toLowerCase(),
            zip_code: this.payerZip,
            country: this.payerCountry,
            associate_type: { BUYER: true }
          }
        }
      }

      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'company',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
            console.info('Associate saved.')
          })
          .catch(function (err) {
            console.error('An error has occured: Associate has not been saved')
            console.error('Error:', err)
          })
      } catch (err) {
        console.error('An error has occured: Associate has not been saved')
        console.error('Error:', err)
      }
    },

    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },

    async getAssociates () {
      var path = `/associates/${state.getCurrentCompany().id}/associate`
      var response = await callAssociates('get', path)

      if (response && response.status === 200 && response.data && response.data.associates) {
        this.defaultAssociates = Object.keys(response.data.associates)
          .map((key) => {
            return response.data.associates[key]
          })
          .filter((associate) => associate.status === 'OK')

        this.defaultAssociates.push({ name: 'Kupac građanin', id: 'kupac-gradanin' })
        // console.log(this.associates)
        this.associates = clone(this.defaultAssociates.sort((a, b) => a.name.localeCompare(b.name)))
        // Get all used up OIBs
        this.associates.forEach(item => {
          if (item?.oibs && item.oibs.length > 0) {
            item.oibs.forEach(oib => {
              this.takenOibs.push(oib)
            })
          }
        })
      } else if (response.status === 404) {
        this.associates = []
        this.showMsgBox({
          text: 'Klijenti nisu pronađeni.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'warning'
        })
      } else {
        this.showMsgBox({
          text: 'Neuspjelo dohvaćanje klijenata',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
      }
    }
  },

  mounted () {
    // document.getElementById('oibInput').click()
    if (state.getPrinter() === 'BIXOLON80') {
      const issueID = 1
      printerInit(issueID)
      state.setPosPrinterSettings(2)
    }

    this.setDefaults()
    this.getMultiplier()
    this.getEuCountries()

    this.payerCountry = 'Hrvatska'

    this.getAssociates()

    this.minIssueDate = toISOLocal(new Date())
    this.listeners.push(
      df
        .doc(`cash_registers/${state.getCashRegister().id}`)
        .onSnapshot((doc) => {
          if (doc && doc.data()) {
            this.offer_sequence = doc.data().offer_sequence
            this.bill_sequence = doc.data().bill_sequence
            this.last_receipt = doc.data().last_receipt_issued_timestamp
            if (this.last_receipt) {
              this.minIssueDate = toISOLocal(
                new Date(this.last_receipt * 1000)
              )
            } else {
              this.minIssueDate = toISOLocal(new Date())
            }
          }
        })
    )
  },
  beforeDestroy () {
    this.detachListeners()
  }
}
</script>

<style>
.input-row {
  max-height: 4.5rem;
}
</style>
