
import axios from 'axios'
import { getToken } from '@/api/token'

async function callAssociates (action, path, payload = {}) {
  var token = await getToken('clients')

  var url = path
  if (process.env.NODE_ENV === 'local') {
    url = '/clients' + path
  }

  switch (action) {
    case 'post':
      try {
        const response = await axios.post(url, payload, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })

        if (response) {
          return response
        }
      } catch (error) {
        return error.response
      }
      break
    case 'get':
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })

        if (response) {
          return response
        }
      } catch (error) {
        return error.response
      }

      break
    case 'patch':

      try {
        const response = await axios.patch(url, payload, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })

        if (response) {
          return response
        }
      } catch (error) {
        return error.response
      }

      break
    case 'delete':

      try {
        const response = await axios.delete(url, {
          headers: {
            Authorization: 'Bearer ' + token
          }
        })

        if (response) {
          return response
        }
      } catch (error) {
        return error.response
      }
  }
}

export { callAssociates }
