<template>
  <v-dialog content-class="my-custom-dialog"
    v-model="showPaymentCenterForm"
    max-width="400px">
    <v-card>
      <v-card-title class="d-flex"
        style="background-color: #1577da; height: 58px; color: white;">
        <span class="text-h5"
          style="font-weight: bold">Mjesto troška</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col class="mb-0 pb-0">
            <v-text-field class="pt-2"
              label="Naziv"
              v-model="newPaymentCenter.name"
              :rules="[rules.req]"
              outlined
              hide-details
              dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mb-0 pb-0 pt-0 mt-0">
            <v-text-field class="pt-2"
              label="Opis"
              v-model="newPaymentCenter.description"
              outlined
              hide-details
              dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6"
            class="mb-0 pb-0 pt-2 mt-0 pr-1">
            <v-text-field label="Ulica"
              v-model="newPaymentCenter.address.street"
              outlined
              hide-details
              dense></v-text-field>
          </v-col>
          <v-col cols="6"
            class="mb-0 pb-0 pt-2 mt-0 pl-1">
            <v-text-field label="Grad"
              v-model="newPaymentCenter.address.city"
              outlined
              hide-details
              dense></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mb-0 pb-0 pt-2 mt-0 pr-1">
            <v-text-field label="Poštanski broj"
              v-model="newPaymentCenter.address.zipCode"
              outlined
              hide-details
              dense></v-text-field>
          </v-col>
          <v-col class="mb-0 pb-0 pt-2 mt-0 pl-1">
            <v-text-field label="Država"
              v-model="newPaymentCenter.address.country"
              outlined
              hide-details
              dense></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex pt-2 mt-0 pr-6 justify-end">
        <v-btn class="btn dangerButton"
          @click="close">Odustani</v-btn>
        <v-btn id="success"
          class="btn successButton"
          @click="submit">Spremi</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'

export default {
  data: function (vm) {
    return {
      newPaymentCenter: { name: '', description: '', address: { street: '', city: '', zipCode: '', country: '' } },
      showPaymentCenterForm: false,
      index: undefined,
      edit: false,
      rules: {
        req: rules.req()
      }
    }
  },
  methods: {
    open (edit = false, costCenter = null, index = undefined) {
      if (edit) {
        this.edit = true
        this.index = index
        this.newPaymentCenter = costCenter
      }
      this.showPaymentCenterForm = true
    },
    close () {
      this.newPaymentCenter = { name: '', description: '', address: { street: '', city: '', zipCode: '', country: '' } }
      this.index = undefined
      this.edit = false
      this.showPaymentCenterForm = false
    },
    submit () {
      if (this.edit) {
        this.$emit('editCostCenter', this.index, this.newPaymentCenter)
      } else {
        this.$emit('addCostCenter', this.newPaymentCenter)
      }
      this.newPaymentCenter = { name: '', description: '', address: { street: '', city: '', zipCode: '', country: '' } }
      this.index = undefined
      this.edit = false
      this.showPaymentCenterForm = false
    }
  }
}
</script>
