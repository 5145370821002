import { getMultiplier } from '@/libs/receiptIssuing/commons'
import state from '@/state'
import { clone } from '@/plugins/utils'

export const remapItems = async (data, paymentMethod, register = undefined) => {

  const multiplier = await getMultiplier()

  const items = data.items.map((item) => {
    let quantity
    if (typeof (item.quantity) === 'string') {
      quantity = parseFloat(item.quantity.replace(',', '.'))
    } else {
      quantity = item.quantity
    }

    var cashRegister = state.getCashRegister()

    if (register) {
      cashRegister = register
    }

    if (cashRegister.type === 'ALL' && paymentMethod === 'T') {
      item = getWholesaleItem(item)
    }

    const it = {
      name: item.name,
      amt: Math.round(quantity * multiplier),
      id: item.id,
      single_item_price: item.prices[data.currency].price,
      selling_unit: item.selling_unit,
      price: Math.round(quantity * item.prices[data.currency].price),
      type: item.type,
      pdv: {
        rate: item.prices[data.currency].taxes[0].rate
      },
      pnp: null
    }

    if (item.prices[data.currency].taxes && item.prices[data.currency].taxes.length > 1) {
      item.prices[data.currency].taxes.forEach(tax => {
        if (tax.name === 'PNP') {
          it.pnp = {
            rate: tax.rate
          }
        }
      })
    }

    if (item.choosenProductOrder) {
      it.menu_products = []
      Object.keys(item.choosenProductOrder).forEach(key => {
        if (item.choosenProductOrder[key] && item.choosenProductOrder[key].length > 0) {
          item.choosenProductOrder[key].forEach(product => {
            if (product && product.productItems && product.productItems.length > 0) {
              product.productItems.forEach(pi => {
                if (pi && pi.id !== '') {
                  it.menu_products.push(pi)
                }
              })
            }
          })
        }
      })
    }

    if (item.discounted_price !== undefined && item.discounted_price > 0) {
      it.discounted_price = item.discounted_price
      it.price = item.discounted_price * quantity
    }

    if (item.discount !== undefined) {
      it.discount = item.discount
    }

    return it
  })

  return items
}

export const getWholesaleItem = (item) => {
  var currency = 'EUR'
  const newItem = clone(item)
  var pnpAmt = 0
  if (newItem.prices[currency].taxes.length > 0) {
    newItem.prices[currency].taxes.forEach(tax => {
      if (tax.name === 'PNP') {
        pnpAmt = tax.rate
      }
    })
  }

  var taxes = newItem.prices[currency].taxes[0].rate + pnpAmt

  newItem.prices[currency].price =
    Math.round(parseFloat(newItem.prices[currency].price / (1 + taxes / 10000)), 2)

  return newItem
}
