<template>
  <v-dialog v-model="visible"
    content-class="my-custom-dialog"
    @keydown.esc="close"
    persistent
    max-width="750"
    style="z-index: 6; height: auto !important">
    <v-overlay v-if="loading"
      absolute
      z-index="10">
      <v-progress-circular indeterminate
        size="64"
        color="primary"></v-progress-circular>
    </v-overlay>
    <v-form ref="editAssociateForm"
      @submit.prevent="submit">
      <v-card tile
        color="white"
        class="pb-3">

        <!-- Title -->
        <v-card-title class="d-flex"
          style="background-color: #1577da; height: 58px; color: white;">
          <span class="text-h5"
            style="font-weight: bold">{{ $t("$vuetify.company.associate") }}</span>
        </v-card-title>

        <!-- Tabs -->
        <v-tabs v-model="activeTab"
          background-color="transparent"
          grow>
          <v-tab v-for="(tab, index) in tabs"
            :key="index">{{ tab }}</v-tab>
        </v-tabs>

        <v-card-text>
          <v-tabs-items v-model="activeTab">

            <!-- Tab 1: Osnovni podaci -->
            <v-tab-item>
              <v-row class="pt-2">
                <v-col cols="6">
                  <v-text-field autofocus
                    class="textField"
                    dense
                    outlined
                    hide-details
                    :label="$t('$vuetify.formDetails.name')"
                    v-model="associate.name"
                    :rules="[rules.req]"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field :label="$t('$vuetify.formDetails.associateCode')"
                    v-model="associate.code"
                    class="textField"
                    dense
                    hide-details
                    outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-combobox dense
                    class="textField"
                    outlined
                    hide-details
                    v-model="associate.oibs"
                    :items="[]"
                    :label="$t('$vuetify.formDetails.oib')"
                    multiple
                    chips
                    clearable>
                    <template v-slot:selection="{ item }">
                      <v-chip outlined
                        small
                        close
                        @click:close="removeOib(item)"
                        color="success">
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
                <v-col cols="6">
                  <v-select :label="$t('$vuetify.formDetails.associateType')"
                    multiple
                    class="textField"
                    hide-details
                    :items="associateTypes"
                    v-model="selectedAssociateTypes"
                    item-text="name"
                    item-value="value"
                    :rules="[rules.arrReq]"
                    small-chips
                    deletable-chips
                    dense
                    outlined></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field label="IBAN"
                    v-model="associate.ibans"
                    class="textField"
                    dense
                    hide-details
                    outlined></v-text-field>
                </v-col>

              </v-row>
              <v-row>
                <v-col>
                  <v-textarea :label="$t('$vuetify.formDetails.additionalInfo')"
                    v-model="associate.additional_info"
                    dense
                    class="textField"
                    outlined></v-textarea>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Tab 2: Adresa -->
            <v-tab-item>
              <v-row class="pt-1">
                <v-col>
                  <v-select :label="$t('$vuetify.formDetails.country')"
                    :items="countries"
                    v-model="associate.country"
                    class="textField"
                    item-text="country"
                    item-value="country"
                    :rules="[rules.req]"
                    dense
                    hide-details
                    outlined></v-select>
                </v-col>
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.address')"
                    v-model="associate.address"
                    :rules="[rules.req]"
                    class="textField"
                    dense
                    hide-details
                    outlined></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.city')"
                    v-model="associate.city"
                    :rules="[rules.req]"
                    class="textField"
                    dense
                    hide-details
                    outlined></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.zipCode')"
                    v-model="associate.zip_code"
                    :rules="[rules.req]"
                    dense
                    class="textField"
                    hide-details
                    outlined></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Tab 3: Kontakt -->
            <v-tab-item>
              <v-row class="pt-1">
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.phone')"
                    v-model="associate.phone"
                    dense
                    outlined
                    class="textField"
                    hide-details></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field :label="$t('$vuetify.formDetails.mobile')"
                    v-model="associate.mobile"
                    dense
                    outlined
                    class="textField"
                    hide-details></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Email"
                    v-model="associate.email"
                    :rules="[rules.email]"
                    dense
                    hide-details
                    class="textField"
                    outlined></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field label="Web"
                    v-model="associate.web"
                    dense
                    hide-details
                    class="textField"
                    outlined></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>

            <!-- Tab 4: Mjesto troška -->
            <v-tab-item>
              <v-row>
                <v-col class="text-left">
                  <v-btn small
                    @click="openCostCenterDialog()"
                    class="btn successButton mb-2"><v-icon>mdi-plus</v-icon></v-btn>

                </v-col>
              </v-row>
              <v-list v-if="associate.cost_centers">

                <span v-if="associate.cost_centers"
                  class="text-h5"
                  style="font-weight: bold">Mjesto
                  troška</span>
                <v-list-item v-for="(key, index) in Object.keys(associate.cost_centers)"
                  :key="index">
                  <v-list-item-content>

                    <v-row>
                      <v-col cols="3">
                        <div class="pl-1 caption grey--text">Naziv</div>
                        <div class="pl-1 text-capitalize">{{ associate.cost_centers[key].name }}</div>
                      </v-col>

                      <v-col cols="3">
                        <div class="pl-1 caption grey--text">Opis</div>
                        <div class="pl-1 text-capitalize">{{ associate.cost_centers[key].description }}</div>
                      </v-col>

                      <v-col cols="4">
                        <div class="pl-1 caption grey--text">Adresa</div>
                        <div class="pl-1 text-capitalize">{{ showAddress(associate.cost_centers[key].address) }}</div>
                      </v-col>

                      <v-col cols="2"
                        class="pt-7">
                        <v-row>
                          <v-btn icon
                            small
                            @click="openEditCostCenterDialog(key, associate.cost_centers[key])">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn icon
                            small
                            color="red"
                            @click="deleteCostCenter(key, associate.cost_centers[key])">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>

                    </v-row>

                  </v-list-item-content>

                </v-list-item>
              </v-list>

            </v-tab-item>

          </v-tabs-items>
        </v-card-text>

        <!-- Actions -->
        <v-card-actions class="d-flex pt-0 mt-0 pr-4 justify-end">
          <v-btn class="btn dangerButton"
            @click="close">Odustani</v-btn>
          <v-btn id="success"
            class="btn successButton"
            :loading="submitting"
            type="submit">Spremi</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
    <cost-center-dialog @addCostCenter="addCostCenter"
      @editCostCenter="editCostCenter"
      ref="costCenterDialog"></cost-center-dialog>
  </v-dialog>
</template>
<script>

import rules from '@/plugins/rules'
import state from '@/state'
import countries from '@/mixins/countries'
import { clone } from '@/plugins/utils'
import requestChecker from '@/mixins/requestChecker'
import { callAssociates } from '@/api/associates'
import CostCenterDialog from './CostCenterDialog.vue'

export default {
  components: {
    CostCenterDialog
  },
  data: function (vm) {
    return {
      existingOibs: undefined,
      filteredOibs: [],
      visible: false,
      submitting: false,
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        email: rules.email(),
        arrReq: rules.arrReq()
      },
      associate: {},
      selectedAssociateTypes: [],
      associateTypes: [
        { name: vm.$t('$vuetify.formDetails.associateBuyer'), value: 'BUYER' },
        {
          name: vm.$t('$vuetify.formDetails.associateSupplier'),
          value: 'SUPPLIER'
        }
      ],
      activeTab: 0,
      tabs: [
        'Osnovni podaci',
        'Adresa',
        'Kontakt',
        'Mjesto troška'
      ],
      paymentCenters: [],
      loading: false
    }
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [countries, requestChecker],
  methods: {
    open (associate, associates) {
      this.existingOibs = associates.flatMap((a) => {
        if (a.oibs && a.oibs.length > 0) {
          return a.oibs.flat(x => x)
        }
      })

      this.filteredOibs = this.existingOibs.filter(oib => {
        if (oib && associate.oibs) {
          return !associate.oibs.includes(oib)
        }
      })

      this.visible = true
      this.submitting = false
      this.associate = Object.assign(this.associate, associate)
      this.setInitialAssociateTypes()

      this.registerRequestParams(this.associate)
    },
    close () {
      this.activeTab = 0
      this.visible = false
    },
    showAddress (address) {
      var adresa = ''
      if (address.street !== '') {
        adresa += address.street
      }

      if (address.city !== '') {
        adresa += ', ' + address.city
      }

      if (address.zip_code !== '') {
        adresa += ' ' + address.zip_code
      }

      if (address.country !== '') {
        adresa += ', ' + address.country
      }

      return adresa
    },

    openCostCenterDialog () {
      this.$refs.costCenterDialog.open(false)
    },
    openEditCostCenterDialog (index, costCenter) {
      var cc = clone(costCenter)
      cc.address.zipCode = cc.address.zip_code
      this.$refs.costCenterDialog.open(true, cc, index)
    },
    async addCostCenter (costCenter) {
      this.loading = true
      var req = {
        name: costCenter.name,
        description: costCenter.description,
        address: {
          street: costCenter.address.street,
          city: costCenter.address.city,
          country: costCenter.address.country,
          zip_code: costCenter.address.zip_code ?? costCenter.address.zipCode
        }
      }

      var path = `/associates/${state.getCurrentCompany().id}/associate/${this.associate.id}/cost-center`
      var response = await callAssociates('post', path, req)

      if (response.status === 200 && response.data) {
        this.loading = false

        var cc = response.data
        cc.address.zipCode = cc.address.zip_code
        this.associate.cost_centers[cc.id] = cc
      } else {
        this.loading = false
        this.showMsgBox({
          text: 'Neuspjelo dodavanje mjesta troška',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    async editCostCenter (index, costCenter) {
      this.loading = true

      var req = {
        name: costCenter.name,
        description: costCenter.description,
        address: {
          street: costCenter.address.street,
          city: costCenter.address.city,
          country: costCenter.address.country,
          zip_code: costCenter.address.zipCode ?? costCenter.address.zip_code
        }
      }

      const keys = Object.keys(req).filter(key => key !== 'company_id')
      const updateMask = keys.join(',')

      var path = `/associates/${state.getCurrentCompany().id}/associate/${this.associate.id}/cost-center/${costCenter.id}?update_mask=${updateMask}`
      var response = await callAssociates('patch', path, req)

      if (response.status === 200 && response.data) {
        this.loading = false
        this.associate.cost_centers[index] = response.data
      } else {
        this.loading = false

        this.showMsgBox({
          text: 'Neuspjelo editiranje mjesta troška',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },

    async deleteCostCenter (index, costCenter) {
      this.loading = true

      var path = `/associates/${state.getCurrentCompany().id}/associate/${this.associate.id}/cost-center/${costCenter.id}`
      var response = await callAssociates('delete', path)

      if (response.status === 200 && response.data) {
        this.loading = false

        delete this.associate.cost_centers[index]
        this.$forceUpdate()
      } else {
        this.loading = false

        this.showMsgBox({
          text: 'Neuspjelo editiranje mjesta troška',
          actions: ['cancel'],
          cancelBtnText: this.$t('$vuetify.close'),
          color: 'error'
        })
      }
    },
    validateOib () {
      let validated = true
      this.associate.oibs.forEach(oib => {
        if (this.filteredOibs.includes(oib)) {
          this.showMsgBox({
            text: this.$t('$vuetify.formDetails.oibDuplicate'),
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })
          validated = false
        }
      })
      return validated
    },
    removeOib (item) {
      this.associate.oibs = this.associate.oibs.filter((o) => o !== item)
      this.$forceUpdate()
    },

    setInitialAssociateTypes () {
      const associateTypes = this.associate.associate_type
        ? clone(this.associate.associate_type)
        : []
      this.selectedAssociateTypes = []
      Object.keys(associateTypes).forEach((key) => {
        if (associateTypes[key]) this.selectedAssociateTypes.push(key)
      })
    },

    async submit () {
      const that = this
      if (!this.$refs.editAssociateForm.validate()) return
      if (!this.validateOib()) return

      this.submitting = true

      const payload = {
        action: {
          operation: 'update',
          entity: 'associate',
          resource_id: this.associate.id,
          params: {
            company_id: state.getCurrentCompany().id,
            name: this.associate.name.toLowerCase(),
            oibs: this.associate.oibs.map((oib) => oib.trim()),
            address: this.associate.address.toLowerCase(),
            city: this.associate.city.toLowerCase(),
            zip_code: this.associate.zip_code,
            country: this.associate.country,
            code: this.associate.code
          }
        }
      }

      payload.action.params.associate_type = this.selectedAssociateTypes.reduce(
        (associateTypes, associateType) =>
          Object.assign(associateTypes, { [associateType]: true }),
        {}
      )

      if (this.associate.additional_info) {
        payload.action.params = {
          ...payload.action.params,
          ...{ additional_info: this.associate.additional_info }
        }
      }
      if (this.associate.mobile) {
        payload.action.params = {
          ...payload.action.params,
          ...{ mobile: this.associate.mobile }
        }
      }
      if (this.associate.phone) {
        payload.action.params = {
          ...payload.action.params,
          ...{ phone: this.associate.phone }
        }
      }
      if (this.associate.email) {
        payload.action.params = {
          ...payload.action.params,
          ...{ email: this.associate.email }
        }
      }
      if (this.associate.web) {
        payload.action.params = {
          ...payload.action.params,
          ...{ web: this.associate.web }
        }
      }
      if (this.associate.ibans) {
        let iban
        if (Array.isArray(this.associate.ibans)) {
          iban = this.associate.ibans
        } else {
          iban = [this.associate.ibans]
        }
        payload.action.params = { ...payload.action.params, ...{ ibans: iban } }
      }

      const cleanParams = this.getCleanParams(this.associate)
      Object.keys(cleanParams).forEach((key) => {
        if (key !== 'company_id' && key !== 'associate_type') {
          delete payload.action.params[key]
        }
      })
      const keys = Object.keys(payload.action.params).filter(key => key !== 'company_id')
      const updateMask = keys.join(',')

      var path = `/associates/${state.getCurrentCompany().id}/associate/${this.associate.id}?update_mask=${updateMask}`
      var response = await callAssociates('patch', path, payload.action.params)

      if (response.status === 200 && response.data) {
        that.$emit('successEdit', response.data)
        setTimeout(() => {
          that.$refs.editAssociateForm.reset()
          that.submitting = false
          that.visible = false
        }, 100)
      } else {
        var text = 'Neuspjelo editiranje klijenta.'
        if (response?.data?.message !== '') {
          text += ' ' + response.data.message
        }

        that.showMsgBox({
          text: text,
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }

      that.$emit('success', payload.action.params)
    }
  }
}
</script>
<style scoped>
.textField>>>input {
  text-transform: uppercase !important;
}

.textField>>>.v-input__slot {
  border-radius: 12px !important;
}
</style>
