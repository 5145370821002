
import { auth, df } from '@/plugins/firebase'
import state from '@/state'
import { v4 as uuidv4 } from 'uuid'
import { dateTimeToUnixTimestamp, getPosNote } from '@/libs/receiptIssuing/commons'
import { remapItems } from '@/libs/receiptIssuing/items'
import { sendHttp, sendVirmanHttp } from '@/libs/receiptIssuing/payment'
import { clone } from '@/plugins/utils'
import { callAssociates } from '@/api/associates'

export default {
  methods: {
    getCashRegisterNumber (cashRegister, offer = false, virman = false) {
      this.cashRegisterType = cashRegister?.type

      if (!cashRegister) {
        return
      }

      if (offer) {
        return cashRegister.offer_sequence + 1
      } else if (virman) {
        return cashRegister.virman_sequence + 1
      } else {
        return cashRegister.bill_sequence + 1
      }
    },

    async getPointOfSale () {
      // if (!this.location?.id) { return }
      // const response = await df.doc(`locations/${this.location.id}`).get()

      // if (!response.data()) {
      //   throw new Error('Nije moguće dohvatiti podatke o prodajnom mjestu')
      // } else {
      //   return response.data()
      // }
    },

    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      const listener = docRef.onSnapshot((doc) => {
        if (doc && doc.data() && doc.data().locations && Object.keys(doc.data().locations).length > 0) {
          const data = doc.data()
          if (data?.locations) {
            this.locations = Object.keys(data.locations)
              .map(key => {
                return data.locations[key]
              })
              .filter(location => location?.resource_status === 'ACTIVE')
              .sort((location1, location2) => {
                if (location1.id < location2.id) return -1
                if (location1.id > location2.id) return 1
              })
          }
        }

        if (this.locations.length === 1) {
          this.location = this.locations[0]
          this.getCashRegisters()
        }
        if (state.getPointOfSale() && state.getPointOfSale().id !== undefined) {
          this.location = state.getPointOfSale()
          this.getCashRegisters()
        }
        this.calculateReferenceNumber()
      })
      this.listeners.push(listener)
    },

    async getCashRegister () {
      if (!this.cashRegister?.id) { return }
      const response = await df.doc(`cash_registers/${this.cashRegister.id}`).get()
      if (!response.data()) {
        throw new Error('Nije moguće dohvatiti podatke o blagajni')
      } else {
        this.cashRegister = response.data()
        return response.data()
      }
    },

    async getCashRegisters () {
      let registers = []
      this.cashRegisters = []

      const res = await df.doc(`user_cash_registers/${auth.currentUser.uid}.${state.getCurrentCompany().id}.${this.location.id}`).get()

      if (res && res.data() && res.data().cash_registers && Object.keys(res.data().cash_registers).length > 0) {
        const data = res.data()
        if (data?.cash_registers) {
          registers = Object.keys(data.cash_registers)
            .map(key => {
              return data.cash_registers[key]
            })
            .filter(register => register?.resource_status === 'ACTIVE')
            .sort((reg1, reg2) => {
              if (reg1.id < reg2.id) return -1
              if (reg1.id > reg2.id) return 1
            })
        }

        registers.forEach(r => {
          this.cashRegisters.push({ ...r, text: r.name, value: r.id, disabled: false })
        })

        if (this.cashRegisters.length === 1) {
          this.cashRegister = this.cashRegisters[0]
          this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister)
          this.setPaymentTypes()

          if (!this.cashRegister.search_type || this.cashRegister.search_type !== 'query') {
            this.getItems()
          }
        }
      }

      // df.collection('cash_registers')
      //   .where('company_id', '==', state.getCurrentCompany().id)
      //   .where('location_id', '==', this.location.id)
      //   .get()
      //   .then((documentSnapshots) => {
      //     if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
      //       documentSnapshots.docs.forEach((location) => {
      //         const loc = location.data()
      //         const register = { ...loc, text: loc.name, value: loc.id, disabled: false }
      //         registers.push(register)
      //       })
      //     }
      //     this.cashRegisters = registers.sort((a, b) => a.text < b.text ? -1 : 1)
      //     if (this.cashRegisters.length === 1) {
      //       this.cashRegister = this.cashRegisters[0]
      //       this.cashRegisterNumber = this.getCashRegisterNumber(this.cashRegister)
      //       this.setPaymentTypes()
      //       this.getItems()
      //     }
      //   })

      this.calculateReferenceNumber()
    },

    getEuCountries () {
      const listener = df.doc('project_constants/countries').onSnapshot(doc => {
        this.euCountries = doc.data().eu_countries
      })
      this.listeners.push(listener)
    },

    getDefaultUnit (warehouseUnits) {
      const defaultunit = warehouseUnits.base_unit
      const unitId = Object.keys(warehouseUnits.units).find((key) => {
        return key === defaultunit
      })
      if (!unitId) {
        console.error('Default key is not defined')
      }
      return unitId
    },

    setSelectedTax (taxes) {
      if (taxes.length > 0) {
        return this.taxes.find(t => t.name === taxes[0].name)
      }
    },

    getOib () {
      let associate

      if (this.associateByName) {
        associate = this.associateByName
      }
      if (this.associateByOib && typeof (this.associateByOib) !== 'string') {
        associate = this.associateByOib
      }

      if (typeof (associate) === 'string') {
        // slobodan unos
        return this.associateByOib
      } else {
        // Odabir
        if (associate) {
          return associate.oibs[0]
        }
      }
    },

    calculateReferenceNumber () {
      let model = '00'
      let receiptNum = clone(this.receiptNumber)
      receiptNum = receiptNum.replaceAll('/', '-')
      if (this.pnbType === 0) {
        let formattedReceiptNumber = receiptNum.replace(/[^0-9-]+/, '')
        formattedReceiptNumber = formattedReceiptNumber.replaceAll('--', '-')
        this.pnbModel = model
        this.pnb = formattedReceiptNumber
      } else if (this.pnbType === 1) {
        const oib = this.getOib()
        if (!oib) return
        model = '01'
        const randomKey = Math.floor(Math.random() * (99999999 - 10000000 + 1)) + 10000000
        const forSecurity = oib.toString() + randomKey.toString()
        const securityNum = this.controlDigit(forSecurity)
        this.pnbModel = model
        this.pnb = oib + '-' + randomKey + '-' + securityNum
      }
    },

    getReferenceNumber () {
      if (this.pnbModel && this.pnb) {
        return `HR${this.pnbModel} ${this.pnb}`
      }
      return ''
    },

    controlDigit (s) {
      var i = 0; var v = 0; var p = 2; var c = ' '
      for (i = s.length; i >= 1; i--) {
        c = s.substr(i - 1, 1)
        if (c >= '0' && c <= '9' && v >= 0) { v = v + p * c; p = p + 1 } else { v = -1 }
      };
      if (v >= 0) { v = 11 - (v % 11); if (v > 9) { v = 0 }; }
      return (v)
    },

    validate (item) {
      if (!item.unit || !item.tax || !item.price) {
        return false
      }
      return true
    },

    getValidationMessage (item) {
      var msg = 'Podaci o proizvodu/proizvodima nepotpuni.'
      msg += '<br/> Na artiklu ' + item.data.name + ' nedostaje '

      if (!item.unit) {
        msg += 'mjerna jedinica'

        if (!item.tax || !item.price) {
          msg += ', '
        }
      }

      if (!item.tax) {
        msg += ' porez'
        if (!item.price) {
          msg += ', '
        }
      }

      if (!item.price) {
        msg += ' cijena.'
      } else {
        msg += '.'
      }

      return msg
    },

    async saveAssociate (associate) {
      const payload = {
        action: {
          operation: 'set',
          entity: 'associate',
          params: {
            company_id: state.getCurrentCompany().id,
            name: associate.name.toLowerCase(),
            oibs: [associate.oib],
            address: associate.address.toLowerCase(),
            city: associate.city.toLowerCase(),
            zip_code: associate.zip_code,
            country: associate.country,
            associate_type: { BUYER: true }
          }
        }
      }

      if (this.costCenters && this.costCenters.length > 0) {
        payload.action.params.cost_centers = this.costCenters.reduce((acc, center) => {
          if (center.id !== -1) {
            var id = uuidv4()
            center.id = id
            acc[id] = center
          }
          return acc
        }, {})
      }

      var path = `/associates/${state.getCurrentCompany().id}/associate`
      var response = await callAssociates('post', path, payload.action.params)

      if (response.status !== 200) {
        this.showMsgBox({
          text: 'Neuspjelo dodavanje novog klijenta',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    },

    // ------------------------------------------------------------- CREATE RECEIPT --
    async createReceipt () {
      // u slucaju da je netko drugi u međuvremenu izdao racun
      await this.getCashRegister()

      this.loading = true
      let validated = true
      this.cart.forEach(it => {
        if (!this.validate(it)) {
          var msg = this.getValidationMessage(it)
          this.loading = false
          validated = false
          this.showMsgBoxHtml({ text: msg, actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
        }
      })
      if (!validated) return

      const method = this.paymentType.value
      const type = this.paymentType.type

      let entity = ''

      if (this.cashRegisterType === 'MP') {
        this.invoiceDate = new Date().toISOString()
        this.valueDate = new Date().toISOString()
      }

      this.cart.forEach(it => {
        it.data.quantity = it.quantity
        if (it.data.discount) {
          it.data.discounted_price *= 100
        }
        if (this.paymentType.value === 'T' || this.paymentType.value === 'P') {
          if (!it.manualPrice) {
            it.data.prices[this.currency].price = it.price * 100
            it.manual_price_input = false
          } else {
            it.manual_price_input = true

            it.data.prices[this.currency].price = Number((it.manualPrice * 100).toFixed(2))
          }
        }

        if (!it.manualPrice) {
          it.data.prices[this.currency].price = Number(it.data.prices[this.currency].price.toFixed(2))
        } else {
          it.manual_price_input = true
          it.data.prices[this.currency].price = Number((it.manualPrice * 100).toFixed(2))
        }

        if (!it.data.prices[this.currency].taxes || it.data.prices[this.currency].taxes.length === 0) {
          const tax = { ...it.tax }
          tax.rate *= 10000
          it.data.prices[this.currency].taxes.push(tax)
        }

        it.data.selling_unit = it.unit.storageUnit ?? it.unit
      })

      const formattedItems = this.cart.map(i => {
        return i.data
      })

      const data = {
        currency: this.currency,
        fullTotal: this.fullTotal,
        items: formattedItems,
        paymentConfig: {},
        registerType: this.cashRegisterType,
        title: '',
        total: this.total,
        totalShoppingCart: 0,
        totalWithDiscount: 0,
        type: method
      }

      const remappedItems = await remapItems(data, method, this.cashRegister)

      let selectPaymentMethod

      let pt = this.paymentType.value
      if (!pt) { pt = this.paymentType }

      switch (pt) {
        case 'G':
          selectPaymentMethod = 'NOVČANICE'
          entity = 'receipt'
          break
        case 'K':
          selectPaymentMethod = 'KARTICA'
          entity = 'receipt'
          break
        case 'O':
          selectPaymentMethod = 'OSTALO'
          entity = 'receipt'
          break
        case 'T':
          selectPaymentMethod = 'TRANSAKCIJSKI RAČUN'
          entity = 'receipt'
          break
        case 'V':
          selectPaymentMethod = 'TRANSAKCIJSKI RAČUN'
          entity = 'virman'
          break
        case 'P':
          selectPaymentMethod = 'TRANSAKCIJSKI RAČUN'
          entity = 'offer'
          break
      }

      var userID = auth.currentUser.uid

      const args = {
        entity: entity,
        reqId: uuidv4(),
        seller_id: userID,
        currency: this.currency,
        paymentMethod: selectPaymentMethod,
        language: this.selectedLanguage,
        type: {
          flag: type
        },
        posNote: await getPosNote(state),
        fiscalize: selectPaymentMethod !== 'TRANSAKCIJSKI RAČUN'
      }

      let associate

      if (this.associateByName) {
        associate = this.associateByName
      }
      if (this.associateByOib && typeof (this.associateByOib) !== 'string') {
        associate = this.associateByOib
      }

      if (typeof (associate) === 'string') {
        // slobodan unos
        args.type.payer_name = associate
        args.type.payer_oib = this.associateByOib
        args.type.payer_address = this.associateAddress
        args.type.payer_zip_code = this.associateZip
        args.type.payer_city = this.associateCity
        args.type.payer_country = this.associateCountry
      } else {
        // Odabir
        if (associate) {
          args.type.payer_address = this.associateAddress
          args.type.payer_city = this.associateCity
          args.type.payer_country = this.associateCountry
          args.type.payer_name = associate.name
          args.type.payer_oib = associate.oibs && associate.oibs[0] ? associate.oibs[0] : ''
          args.type.payer_zip_code = this.associateZip
        }
      }

      if (this.doSaveAccount) {
        if (this.takenOibs.includes(type.payer_oib)) {
          this.doSaveAccount = false
          this.saveAssociateEnabled = false
          return
        }

        var associateForSave = {
          address: args.type.payer_address,
          city: args.type.payer_city,
          country: args.type.payer_country,
          name: args.type.payer_name,
          oib: args.type.payer_oib,
          zip_code: args.type.payer_zip_code

        }
        this.saveAssociate(associateForSave)
      }

      // provjeri da li je datum izdavanja racuna promijenjen
      // ako nije, koristi this.issueDateTime koji ima točno vrijeme
      // ako je, koristi this.invoiceDate koji je odabran iz kalendara pa nema točno vrijeme (UTC 12:00 + 1h)

      const issueDateTimeObj = new Date(this.issueDateTime * 1000)
      const invoiceDateObj = new Date(this.invoiceDate)
      let issueDateToUse

      if (issueDateTimeObj.getFullYear() !== invoiceDateObj.getFullYear() ||
        issueDateTimeObj.getMonth() !== invoiceDateObj.getMonth() ||
        issueDateTimeObj.getDate() !== invoiceDateObj.getDate()) {
        issueDateToUse = dateTimeToUnixTimestamp(new Date(this.invoiceDate))
      } else {
        issueDateToUse = this.issueDateTime
      }

      if (selectPaymentMethod === 'NOVČANICE' || selectPaymentMethod === 'KARTICE') {
        issueDateToUse = dateTimeToUnixTimestamp(new Date())
      }

      const httpArgs = {
        issueDateTime: issueDateToUse,
        paymentMeth: args.paymentMethod,
        fiscalize: args.fiscalize,
        language: args.language,
        type: args.type,
        openPdfPrint: this.openPdfPrint,
        receiptNumber: this.cashRegisterNumber
      }

      if (this.selectedCostCenter && this.selectedCostCenter.id !== -1) {
        httpArgs.costCenter = this.selectedCostCenter
      }

      var offer = false
      if (this.paymentType.value === 'P') {
        offer = true
      }

      var virman = false
      if (this.paymentType.value === 'V') {
        virman = true
      }

      try {
        httpArgs.receiptNumber = await this.getCashRegisterNumber(this.cashRegister, offer, virman)
      } catch (err) {
        this.hideLoader()
        return
      }

      this.cashRegisterNumber = httpArgs.receiptNumber
      this.calculateReferenceNumber()

      const items = remappedItems

      items.forEach(i => {
        i.price = Number(i.price.toFixed(2))

        if (i.discounted_price) {
          i.discounted_price = Number(i.discounted_price.toFixed(2))
        }
      })

      const payload = this.getPayload(args, items)

      httpArgs.msgBox = this.showMsgBox
      httpArgs.confirm = this.confirm
      httpArgs.confirmClose = this.confirmClose
      httpArgs.hideLoader = this.hideLoader

      if (this.cashRegisterType !== 'MP') { httpArgs.overrideValueDateTime = dateTimeToUnixTimestamp(new Date(this.valueDate)) }
      if (this.deliveryDate && entity === 'receipt' && this.cashRegisterType !== 'MP') { httpArgs.delivery_date = dateTimeToUnixTimestamp(new Date(this.deliveryDate)) }

      data.fullTotal = Math.round(data.fullTotal)
      data.total = Math.round(data.total)

      if (data.fullTotal !== undefined && data.fullTotal > 0) {
        payload.action.params.full_total = data.fullTotal
      } else {
        payload.action.params.full_total = data.total
      }

      payload.action.params.number = this.cashRegisterNumber
      payload.action.params.full_total = this.fullTotal * 100
      payload.action.params.total = this.total * 100

      // Poziv na broj
      payload.action.params.payment_reference = this.getReferenceNumber()

      if (['T58', 'T80', 'A4'].includes(state.getPrinter().value)) {
        payload.action.params.print_size = state.getPrinter().value
      } else {
        payload.action.params.print_size = 'BIXOLON80'
      }

      payload.action.params.note = this.note

      // POTREBNO ZBOG ISPISA ČLANKA NA RAČUNU
      if ((pt === 'T' || pt === 'P') && this.associateCountry !== undefined && this.associateCountry !== 'Hrvatska') {
        if (state.getCurrentCompany().is_taxed === true) {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              this.wholeSaleRcptType = 'TAX_EU_VIES_TRUE'
            } else {
              this.wholeSaleRcptType = 'TAX_EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              this.wholeSaleRcptType = 'TAX_OTHER_PERSON'
            } else {
              this.wholeSaleRcptType = 'TAX_OTHER_LEGAL_ENTITY'
            }
          }
        } else {
          if (this.inEu) {
            if (this.selectedVies === 'inside') {
              this.wholeSaleRcptType = 'EU_VIES_TRUE'
            } else {
              this.wholeSaleRcptType = 'EU_VIES_FALSE'
            }
          } else {
            if (this.selectedCustomerType === 'person') {
              this.wholeSaleRcptType = 'OTHER_PERSON'
            } else {
              this.wholeSaleRcptType = 'OTHER_LEGAL_ENTITY'
            }
          }
        }
      }

      if (this.wholeSaleRcptType !== '') {
        payload.action.params.wholesale_receipt_type = this.wholeSaleRcptType
      }

      // if currency is overriden
      if (this.selectedCurrency !== data.currency) {
        data.currency = this.selectedCurrency
        payload.action.params.currency = this.selectedCurrency
      }

      const additionalData = {
        locationId: this.location.id,
        registerId: this.cashRegister.id,
        callNewPrint: true,
        cashRegister: this.cashRegister
      }

      if (args.entity === 'virman') {
        await sendVirmanHttp(data, items, payload, httpArgs, this.selectedPrinter, additionalData)
      } else {
        await sendHttp(data, items, payload, httpArgs, this.selectedPrinter, additionalData)
      }
    },

    getPayload (args, items) {
      return {
        action: {
          operation: 'set',
          entity: args.entity,
          params: {
            company_id: state.getCurrentCompany().id,
            location_id: this.location.id,
            register_id: this.cashRegister.id,
            issue_date_time: dateTimeToUnixTimestamp(new Date(this.invoiceDate)),
            payment_method: args.paymentMethod,
            seller_oib: '',
            currency: args.currency,
            language: args.language.value,
            type: args.type,
            items: items,
            note: args.posNote,
            fiscalize: args.fiscalize
          }
        }
      }
    }
  }
}
